.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #fff;
}
hr {
  width: 100%;
  border: 1px solid #f2f2f2;
}

.title-time {
  width: 500px;
  height: 40px;
  margin-top: 30px;
  color: #10b3ed;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 37px;
  line-height: 40px;
}

.sub-title-time {
  color: #00a3dd;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

.selectuser {
  box-sizing: border-box;
  margin-right: 10px;
  width: 200px;
  height: 50px;
  padding: 12px 18px;
  border-radius: 5px;
  border: none;
  background: #f2f2f2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  margin-top: 10px;
  //appearance: none;
}

.downloadLink {
  position: relative;
  color: #10b3ed;
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-right: 35px;
}

.downloadLinkIcon {
  position: absolute;
  margin-left: 19px;
}

.chart-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #3c3c3c;
  margin-bottom: 18px;
}

.select-module {
  width: 156.05px;
  height: 43px;
  left: 667px;
  top: 917px;
  background: #f2f2f2;
  border-radius: 5px;
}

.selectuser-date {
  box-sizing: border-box;
  margin-right: 10px;
  margin-left: 5px;
  width: 200px;
  padding: 13px 18px;
  border-radius: 5px;
  border: none;
  background: #f2f2f2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  margin-top: 10px;
}

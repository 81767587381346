@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
:root {
  /*colors*/
  --primary-blue: #58cdf6;
  --primary-green: #7dce30;
  --primary-orange: #ffa026;
  --primary-black: #3c3c3c;
  --primary-gray: #ccc;
  --tab-inactive: #00a3dd;
  --profile-header-detail: #93f2ee;
  --modulo-1: #58cdf7;
  --modulo-2: #78dc36;
  --modulo-3: #ff9e30;
  --modulo-4: #fdc52f;
  --modulo-5: #00e3d4;
  --modulo-6: #ff78a3;
  --modulo-7: #708fff;
}

audio {
  display: none;
}

.global_bg {
  background-color: #f2f2f2;
}

input,
option,
select {
  font-family: "Poppins", sans-serif;
}

/*App*/
.App {
  background-color: white;
  background-image: url("./assets/images/pointsBG.png");
  background-size: 30px;
  min-height: 100vh;
  margin: auto;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}

button {
  font-family: "Poppins", sans-serif !important;
  line-height: 1.15;
}

.button-disabled {
  opacity: 0.5;
}

/*Tab*/
.Tab {
  text-align: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: 800px;
  background-color: var(--primary-blue) !important;
  color: var(--tab-inactive) !important;
  height: 80px;
  justify-content: space-around;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

.tab-link {
  width: 33%;
  display: block;
  padding-top: 11px;
  text-decoration: none !important;
  color: var(--tab-inactive) !important;
}

.tb-active {
  color: white !important;
}

.tab-link:active {
  background-color: var(--primary-blue) !important;
}

/*Home*/
.Home {
  align-items: center;
  max-width: 800px;
  height: calc(100vh - 80px);
  background-color: #e9f4ff;
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  max-width: 800px;
  z-index: 101;
  backdrop-filter: blur(2px);
}

@media only screen and (min-width: 800px) {
  .overlay {
    left: calc(50% - 400px);
  }
}

.home-mykure {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 120px;
  height: auto;
}

.home-monai {
  position: absolute;
  bottom: 200px;
  right: 50px;
  width: 120px;
  height: auto;
}

.home-child {
  position: absolute;
  //bottom: 210px;
  right: 550px;
  width: 80px;
  top: 20px;
  height: auto;
}

@media only screen and (max-width: 500px) {
  .home-child {
    display: none;
  }
}

@media only screen and (min-width: 800px) {
  .home-mykure {
    left: calc(50% - 350px);
  }
  .home-monai {
    right: calc(50% - 350px);
  }
  .home-child {
    left: calc(50% - 302px);
  }
}

@media only screen and (max-width: 550px) {
  .home-mykure,
  .home-monai {
    display: none;
  }
}

.home-modal {
  padding: 27px 20px;
  z-index: 102;
  height: 546px;
  width: 270px;
  background-color: white;
  border-radius: 20px;
  /*Centering*/
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

.level-modal {
  padding: 20px 20px;
  z-index: 102;
  background-color: white;
  color: var(--primary-blue);
  border-radius: 15px;
  /*Centering*/
  position: absolute;
  margin-right: -50%;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.2);
}

.lm-body {
  padding-top: 15px;
  padding-bottom: 17px;
  font-size: 14px;
}

.lmodal-1 {
  color: var(--modulo-1) !important;
}
.lmodal-2 {
  color: var(--modulo-2) !important;
}
.lmodal-3 {
  color: var(--modulo-3) !important;
}
.lmodal-4 {
  color: var(--modulo-4) !important;
}
.lmodal-5 {
  color: var(--modulo-5) !important;
}
.lmodal-6 {
  color: var(--modulo-6) !important;
}
.lmodal-0 {
  color: var(--modulo-7) !important;
}

.modal-closeButton {
  display: inherit;
  align-self: end;
  margin-left: auto;
}

.modal-header {
  display: flex;
  font-weight: 500;
  font-size: 18px;
}

.home-modal > .modal-header {
  color: var(--primary-orange);
}

.small-header {
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
}

.modal-content {
  height: 440px;
}

.modal-content-page {
  display: inline-block;
  color: var(--primary-orange);
  margin: 0px;
  width: 40px;
  font-weight: 600;
  font-size: 37px;
  vertical-align: top;
}

.modal-description {
  text-align: justify;
  padding-top: 20px;
  display: inline-block;
  width: 230px;
  height: 86px;
  font-size: 14px;
}

.modal-image-container {
  text-align: center;
  margin-top: 15px;
}

.modal-image {
  height: 295px;
  /*Shadows for every platform*/
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
}

.lm-footer {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.lm-button {
  color: white;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 8px 25px;
  background: white;
  border-radius: 100px;
  border: none;
  outline: none !important;
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.lm-certificate {
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 6px 23px;
  background: white;
  border-radius: 100px;
  border: none;
  outline: none !important;
  margin: auto;
  margin-top: 12px;
  width: 180px !important;
}

.locked {
  background-color: var(--primary-gray);
}

.lmb-1 {
  background-color: var(--modulo-1);
}

.lmc-1 {
  border: 2px solid var(--modulo-1);
  color: var(--modulo-1);
}

.lmb-2 {
  background-color: var(--modulo-2);
}

.lmc-2 {
  border: 2px solid var(--modulo-2);
  color: var(--modulo-2);
}

.lmb-3 {
  background-color: var(--modulo-3);
}

.lmc-3 {
  border: 2px solid var(--modulo-3);
  color: var(--modulo-3);
}

.lmb-4 {
  background-color: var(--modulo-4);
}

.lmc-4 {
  border: 2px solid var(--modulo-4);
  color: var(--modulo-4);
}

.lmb-5 {
  background-color: var(--modulo-5);
}

.lmc-5 {
  border: 2px solid var(--modulo-5);
  color: var(--modulo-5);
}

.lmb-6 {
  background-color: var(--modulo-6);
}

.lmc-6 {
  border: 2px solid var(--modulo-6);
  color: var(--modulo-6);
}

.lmb-0 {
  background-color: var(--modulo-7);
}

.lmc-0 {
  border: 2px solid var(--modulo-7);
  color: var(--modulo-7);
}

.arrow-container {
  display: inline-block;
  padding-top: 7px;
  width: 75px;
}

.empty-button {
  display: inline-block;
  width: 103.4px;
}

.modal-footer {
  text-align: center;
  height: 108px;
}
.modal-okButton {
  vertical-align: top;
  color: var(--primary-orange);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 10px 40px;
  background: white;
  border: solid 2px var(--primary-orange);
  border-radius: 100px;
  outline: none !important;
  /*Shadows for every platform*/
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
}

.modal-pages {
  margin-top: 15px;
  text-align: center;
}

.modal-page {
  display: inline-block;
  height: 6px;
  width: 6px;
  background-color: var(--primary-blue);
  opacity: 0.3;
  border-radius: 3px;
  margin: 0px 11px;
}

.mp-enabled {
  opacity: 1;
}

.levels-container {
  width: 300px;
  height: 450px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.required-data {
  position: absolute;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  max-width: 800px;
  height: 100vh;
  overflow-y: hidden;
  background-color: white;
  z-index: 100;
  text-align: center;
}

@media only screen and (min-width: 800px) {
  .required-data {
    left: calc(50% - 400px);
  }
}

.rd-title {
  padding-top: 10px;
}

.rd-title-fullWidth {
  font-weight: 500;
  font-size: 19px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  margin: 17px 20px;
}

.rd-question {
  margin: 55px 0px 20px;
  font-size: 20px;
  font-weight: 500;
}

.rd-body {
  height: calc(100vh - 158px);
  background-image: url("./assets/images/curveBlueLarge.svg");
  background-position-x: center;
  background-size: cover;
  padding-top: 80px;
  color: white;
}

.rd-input {
  display: inline-block;
  color: white;
  outline: none;
  width: calc(100% - 100px);
  padding: 4px 0px;
  border: none;
  border-bottom: 2px solid white;
  letter-spacing: 1.6px;
  font-size: 12px;
  background: none;
  margin-bottom: 40px;
}

/*For input autocomplete (institutions)*/
.css-yk16xz-control,
.css-1pahdxg-control {
  background: none !important;
  border: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  min-height: 0px !important;
  margin: 0px 2px;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.css-1wa3eu0-placeholder,
.css-1uccc91-singleValue {
  color: white !important;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  color: white !important;
  padding: 0px !important;
  height: 16px;
  width: 16px;
  margin-bottom: 4px !important;
  margin-right: -1px !important;
}

.css-2b097c-container {
  padding: 0px 0px 2px !important;
  text-align: initial !important;
}

.css-b8ldur-Input,
.css-g1d714-ValueContainer {
  padding: 0px !important;
  color: white !important;
}

.rd-input > span {
  background: none !important;
}

.rd-input > div {
  color: #10b3ed !important;
}

.css-2613qy-menu {
  border-radius: 0px !important;
}
/*###*/

.rd-input-uppercase {
  text-transform: uppercase;
}

.rd-text {
  margin: 0px 40px 60px;
  text-align: center;
}

.rd-button {
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 6px;
  padding: 15px 40px;
  background-color: var(--primary-green);
  border-radius: 20px;
  border: none;
  outline: none !important;
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 5px;
}

.reaccion-logo {
  position: absolute;
  bottom: 22px;
  left: 50%;
  transform: translate(-50%, 0%);
}

@media only screen and (max-height: 560px) {
  .reaccion-logo {
    display: none;
  }
}

/*Iterable levels*/
.margine-0 {
  --extra-top: 0px;
}
.margine-1 {
  --extra-top: 203px;
}
.margine-2 {
  --extra-top: 406px;
}
.margine-3 {
  --extra-top: 609px;
}
.margine-4 {
  --extra-top: 812px;
}
.margine-5 {
  --extra-top: 1015px;
}
.margine-6 {
  --extra-top: 1218px;
}
.margine-7 {
  --extra-top: 1421px;
}
.margine-8 {
  --extra-top: 1624px;
}
.margine-9 {
  --extra-top: 1827px;
}
.margine-10 {
  --extra-top: 2030px;
}
.levels-container-iterable {
  position: fixed;
  width: 100%;
  max-width: 800px;
  height: calc(100vh - 80px);
  display: flex;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
}

.level-resto-1 {
  height: 77px;
  position: absolute;
  top: calc(20px + var(--extra-top));
  left: calc(50% - 7px);
  transform: translate(-50%, 0px);
  /*This allows scroll 20px under last lvl rendered*/
  margin-bottom: 20px;
}

.level-resto-2 {
  height: 86px;
  position: absolute;
  top: calc(94px + var(--extra-top));
  left: calc(50% + 28px);
  transform: translate(-50%, 0px);
  /*This allows scroll 20px under last lvl rendered*/
  margin-bottom: 20px;
}

.level-resto-0 {
  height: 74px;
  position: absolute;
  top: calc(161px + var(--extra-top));
  left: calc(50% - 21px);
  transform: translate(-50%, 0px);
  /*This allows scroll 20px under last lvl rendered*/
  margin-bottom: 20px;
}

.circle-resto-1 {
  height: 70px;
  position: absolute;
  top: calc(28px + var(--extra-top));
  left: calc(50% - 87px);
}

.circle-resto-2 {
  height: 70px;
  position: absolute;
  top: calc(112px + var(--extra-top));
  left: calc(50% + 36px);
}

.circle-resto-0 {
  height: 70px;
  position: absolute;
  top: calc(151px + var(--extra-top));
  left: calc(50% - 91px);
}

.circle-number {
  font-size: 24px;
  font-weight: 700;
  color: white;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 68px;
  text-align: center;
  height: 67px;
}

.ribbon-resto-1 {
  position: absolute;
  top: calc(50px + var(--extra-top));
  left: calc(50% - 20px);
}

.ribbon-resto-2 {
  position: absolute;
  top: calc(122px + var(--extra-top));
  left: calc(50% + 14px);
}

.ribbon-resto-0 {
  position: absolute;
  top: calc(184px + var(--extra-top));
  left: calc(50% - 27px);
}

.locked-resto-1 {
  height: 70px;
  position: absolute;
  top: calc(50px + var(--extra-top));
  left: calc(67% - 87px);
}

.locked-resto-2 {
  height: 70px;
  position: absolute;
  top: calc(120px + var(--extra-top));
  left: calc(46% + 36px);
}

.locked-resto-0 {
  height: 70px;
  position: absolute;
  top: calc(185px + var(--extra-top));
  left: calc(66% - 91px);
}

.play-resto-1 {
  height: 70px;
  position: absolute;
  top: calc(30px + var(--extra-top));
  left: calc(59% - 87px);
}

.play-resto-2 {
  height: 70px;
  position: absolute;
  top: calc(100px + var(--extra-top));
  left: calc(25% + 36px);
}

.play-resto-0 {
  height: 70px;
  position: absolute;
  top: calc(173px + var(--extra-top));
  left: calc(57% - 91px);
}

@media screen and (min-width: 500px) {
  .level-resto-1 {
    left: calc(50% - 20px);
  }
  .level-resto-2 {
    left: calc(50% - -10px);
  }
  .level-resto-0 {
    left: calc(50% - 38px);
  }
  .ribbon-resto-1 {
    left: calc(50% - 40px);
  }
  .ribbon-resto-2 {
    left: calc(50% - -2px);
  }
  .ribbon-resto-0 {
    left: calc(50% - 44px);
  }
  .circle-resto-1 {
    left: calc(50% - 107px);
  }
  .circle-resto-2 {
    left: calc(50% - -18px);
  }
  .circle-resto-0 {
    left: calc(50% - 108px);
  }
  .locked-resto-1 {
    /*height: 70px;
    position: absolute;
    top: calc(50px + var(--extra-top));*/
    left: calc(56% - 87px);
  }

  .locked-resto-2 {
    /*height: 70px;
    position: absolute;
    top: calc(120px + var(--extra-top));*/
    left: calc(46% + 36px);
  }

  .locked-resto-0 {
    /*height: 70px;
    position: absolute;
    top: calc(185px + var(--extra-top));*/
    left: calc(55% - 91px);
  }
  .play-resto-1 {
    height: 70px;
    position: absolute;
    top: calc(30px + var(--extra-top));

    left: calc(52% - 87px);
  }

  .play-resto-2 {
    height: 70px;
    position: absolute;
    top: calc(100px + var(--extra-top));

    left: calc(35% + 36px);
  }

  .play-resto-0 {
    height: 70px;
    position: absolute;
    top: calc(173px + var(--extra-top));

    left: calc(51% - 91px);
  }
}

/*#########*/

.how-to-play-button {
  position: fixed;
  bottom: 98px;
  left: 10px;
}
.share-button {
  position: fixed;
  bottom: 85px;
  right: 10px;
}
.trigger-sound-button {
  position: fixed;
  bottom: 85px;
  right: 10px;
  top: 18px;
  height: 75px;
}

@media only screen and (min-width: 800px) {
  .how-to-play-button {
    left: calc(50% - 390px);
  }

  .trigger-sound-button {
    left: calc(50% + 302px);
  }
  .share-button {
    left: calc(50% + 302px);
  }
}

.colorPrimary {
  color: var(--primary-blue);
  text-align: center;
}

/*Profile*/
/*header*/
.profile-header {
  background: linear-gradient(
    0deg,
    rgba(16, 179, 237, 1) 0%,
    rgba(84, 204, 246, 1) 100%
  );
  color: var(--primary-black);
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 500;
  height: 138px;
}

.ph-avatar-container {
  min-width: 40px;
  max-width: 120px;
  display: inline-block;
  width: 25%;
  height: 98px;
  margin: 20px 16px;
  /*centering image*/
  position: relative;
}

.ph-avatar {
  border-radius: 100px;
  border: 2px solid var(--profile-header-detail);
  /*centering image*/
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  /*ignore borders*/
  transform: translate(-2px, 0px);
}

.ph-details {
  color: white;
  display: inline-block;
  margin: 20px 16px;
  height: 98px;
  /*on inline-block, set vertical align to top for avoid the next blocks to move down*/
  vertical-align: top;
}

@media only screen and (min-width: 480px) {
  .ph-details {
    width: calc(100% - 184px);
  }
}

@media only screen and (max-width: 480px) {
  .ph-details {
    width: calc(75% - 64px);
  }
}
.ph-details-buttons {
  padding-top: 30px;
  align-content: space-between;
}

.ph-button-content {
  display: inline-block;
  width: calc(100% - 32px);
}

.ph-settings {
  display: inline-block;
  vertical-align: middle;
}

.ph-button {
  color: white;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  max-width: 166px;
  width: calc(100% - 10px);
  min-width: 139px;
  padding: 12px 15px;
  background: none;
  border: solid 2px white;
  border-radius: 100px;
  outline: none !important;
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.1);
}

/*tabsd*/
.profile-tabs {
  display: inline-block;
  background-color: #c8fdfb;
  color: var(--profile-header-detail);
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  padding: 14px 0px 17px;
  width: 50%;
  border-bottom: solid 2px white;
}

.pt-active {
  color: var(--tab-inactive);
  border-bottom: solid 2px var(--tab-inactive) !important;
}

/*Content*/
.profile-content {
  padding: 0px 15px;
  height: calc(100vh - 270px);
  overflow-y: scroll;
  background-color: white;
}
.medals-profile {
  display: flex;
}
.medals-profile .medal-container {
  margin-right: 20px;
}
.profile-item {
  padding: 20px;
  border-bottom: solid 2px #f2f2f2;
}

.profile-item-title {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  padding-bottom: 12px;
}

.pit-data {
  color: var(--primary-orange) !important;
}

.pit-achievements {
  color: var(--modulo-6) !important;
}

.profile-item-body {
  padding-bottom: 4px;
  /*Cut the word if is too long*/
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.profile-avatars,
.profile-settings,
.ranking-modal,
.termsAndConditions,
.privacityPolicy {
  padding: 25px 20px;
  z-index: 102;
  height: calc(100vh - 90px);
  width: calc(100% - 80px);
  max-width: 720px;
  background-color: white;
  border-radius: 20px;
  /*Centering*/
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

.pa-title {
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  color: var(--primary-blue);
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 32px;
  width: calc(100% - 30px);
}

.pa-avatars-scroll {
  height: calc(100vh - 147px);
  overflow-y: scroll;
}

.pa-closeButton {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.pa-avatar-container {
  text-align: center;
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 12px);
  height: 24%;
  /*padding-bottom: calc(50% - 8px);*/
  margin: 6px;
}

.pa-avatar {
  max-width: 100%;
  max-height: 100%;
}

.pa-selected {
  opacity: 0.5;
  filter: blur(2px);
}

/*Profile settings*/
.ps-scroll {
  height: calc(100vh - 210px);
  overflow-y: auto;
}

.profile-settings-margin {
  margin: 0px 1rem;
}

.ps-input,
.ps-select {
  color: #10b3ed;
  outline: none;
  padding: 4px 0px;
  border: none;
  letter-spacing: 1.2px;
  font-size: 14px;
  background: none;
  width: 100%;
  margin-bottom: 35px;
}

.ps-select__single-value,
.ps-select .css-b8ldur-Input,
.ps-select .css-g1d714-ValueContainer {
  color: #10b3ed !important;
  letter-spacing: 1.2px;
  font-size: 14px;
}

.ps-select__placeholder {
  color: #10b3ed88 !important;
  letter-spacing: 1.2px;
  font-size: 14px;
}

.ps-button-container {
  text-align: center;
}

.ps-button {
  margin-top: 26px;
  color: var(--primary-blue);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 10px 40px;
  background: white;
  border: solid 2px var(--primary-blue);
  border-radius: 100px;
  outline: none !important;
  /*Shadows for every platform*/
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
}

.ps-changePass {
  font-size: 14px;
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  color: #10b3ed;
  padding: 8px 15px;
  border: 2px solid #10b3ed;
  border-radius: 30px;
  margin: 10px 0px 45px;
}
/*#####*/

/*LevelPage*/
.LevelPage {
  height: 100%;
}

.lp-content {
  padding-top: 10px;
  vertical-align: middle;
  height: 100vh;
}

.lp-bg1 {
  background-color: var(--modulo-1);
}

.lp-bg2 {
  background-color: var(--modulo-2);
}

.lp-bg3 {
  background-color: var(--modulo-3);
}

.lp-bg4 {
  background-color: var(--modulo-4);
}

.lp-bg5 {
  background-color: var(--modulo-5);
}

.lp-bg6 {
  background-color: var(--modulo-6);
}

.lp-bg0 {
  background-color: var(--modulo-7);
}

.lp-back {
  position: fixed;
  top: 23px;
  left: 23px;
  color: white;
}
@media only screen and (min-width: 800px) {
  .lp-back {
    left: calc(50% - 377.5px);
  }
}

.lp-title {
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  margin: 17px 60px;
  color: white;
}

.lp-paraguayoite {
  position: absolute;
  top: 75px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80%;
  max-width: 320px;
}

.lp-levelAvatar {
  position: absolute;
  top: 90px;
  left: 50%;
  transform: translate(-50%, 25%);
  border-radius: 100px;
  width: 52%;
  max-width: 208px;
}

.lp-startButton {
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 6px;
  padding: 16px 50px;
  background-color: var(--primary-orange);
  border-radius: 20px;
  border: none;
  outline: none !important;
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.4);
}

/*@media only screen and (min-width: 800px) {
  .lp-paraguayoite {
    left: 240px;
    transform: translate(0%, 0);
  }
  .lp-levelAvatar {
    left: 296px;
    transform: translate(0%, 25%);
  }
}*/

.lp-spaceForImage {
  margin-top: 91%;
  text-align: center;
}

@media only screen and (min-width: 400px) {
  .lp-spaceForImage {
    margin-top: 364px;
  }
}

.lp-friends {
  color: var(--primary-green);
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.5px;
  height: 108px;
  background-color: white;
  width: calc(100% - 60px);
  max-width: 740px;
  margin-top: 36px;
  padding: 15px 30px;
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
}

.lpf-list {
  overflow-x: scroll;
  margin-top: 16px;
  white-space: nowrap;
}

.lpf-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 80px;
}

.lpf-avatar {
  height: 42px;
  width: 42px;
  border-radius: 30px;
  border: 1px solid var(--primary-blue);
}

.lpf-name {
  font-size: 12px;
  color: black;
  font-weight: 400;
  white-space: normal;
  word-wrap: break-word;
}

.lp-level1 {
  /*background: linear-gradient(0deg, rgba(16,179,237,1) 0%, rgba(84,204,246,1) 100%);*/
  color: var(--modulo-1);
  --paraguayoite: url("./assets/images/modulos/paraguayoite1.svg");
}
.lpb-1 {
  filter: invert(68%) sepia(73%) saturate(465%) hue-rotate(163deg)
    brightness(96%) contrast(100%);
}

.lp-level2 {
  /*background: linear-gradient(0deg, #6fb52d 0%, #84d637 100%);*/
  color: var(--modulo-2);
  --paraguayoite: url("./assets/images/modulos/paraguayoite2.svg");
}
.lpb-2 {
  filter: invert(72%) sepia(29%) saturate(1120%) hue-rotate(44deg)
    brightness(98%) contrast(82%);
}

.lp-level3 {
  /*background: linear-gradient(0deg, #da8315 0%, #fda738 100%);*/
  color: var(--modulo-3);
  --paraguayoite: url("./assets/images/modulos/paraguayoite3.svg");
}
.lpb-3 {
  filter: invert(72%) sepia(73%) saturate(1985%) hue-rotate(337deg)
    brightness(101%) contrast(107%);
}

.lp-level4 {
  /*background: linear-gradient(0deg, #e8a82c 0%, #ffcd18 100%);*/
  color: var(--modulo-4);
  --paraguayoite: url("./assets/images/modulos/paraguayoite4.svg");
}
.lpb-4 {
  filter: invert(71%) sepia(67%) saturate(516%) hue-rotate(344deg)
    brightness(100%) contrast(103%);
}

.lp-level5 {
  /*background: linear-gradient(0deg, #00D8CB 0%,  #10CFA1 100%);*/
  color: var(--modulo-5);
  --paraguayoite: url("./assets/images/modulos/paraguayoite5.svg");
}
.lpb-5 {
  filter: invert(81%) sepia(25%) saturate(7165%) hue-rotate(125deg)
    brightness(93%) contrast(101%);
}

.lp-level6 {
  /*background: linear-gradient(0deg, #dc4a5c 0%, #ff8c99 100%);*/
  color: var(--modulo-6);
  --paraguayoite: url("./assets/images/modulos/paraguayoite6.svg");
}
.lpb-6 {
  filter: invert(51%) sepia(17%) saturate(3667%) hue-rotate(317deg)
    brightness(113%) contrast(106%);
}

.lp-level7 {
  /*background: linear-gradient(0deg, #6788ff 0%, #587cff 100%);*/
  color: var(--modulo-7);
  --paraguayoite: url("./assets/images/modulos/paraguayoite7.svg");
}
.lpb-0 {
  filter: invert(55%) sepia(49%) saturate(1365%) hue-rotate(201deg)
    brightness(98%) contrast(106%);
}

/*Dashboard*/

.Dashboard {
  padding-top: 23px;
  height: calc(100vh - 120px);
  overflow-y: scroll;
}

.dashboard-tabs {
  position: fixed;
  top: 30px;
  left: 0px;
  background-color: white;
  width: calc(100% - 40px);
  max-width: 760px;
  padding: 20px;
  /*Shadows for every platform*/
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

@media only screen and (min-width: 800px) {
  .dashboard-tabs {
    left: calc(50% - 400px);
  }
}

.dashboard-tab {
  width: calc(50% - 20px);
  margin: 0px 8px;
  display: inline-block;
  background-color: white;
  color: var(--primary-green);
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding: 12px 0px;
  border: solid 2px var(--primary-green);
  border-radius: 20px;
}

.d-tab-active {
  color: white;
  background-color: var(--primary-green);
}

.d-dropdown {
  color: #10b3ed;
  outline: none;
  margin: 0px 30px 36px;
  padding: 4px 0px;
  border: none;
  border-bottom: 2px solid #10b3ed;
  letter-spacing: 1.2px;
  font-size: 17px;
  font-weight: 500;
  background: none;
  width: calc(100% - 60px);
}

.d-data-number {
  margin: 10px 0px 10px 32px;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: #10b3ed;
  width: 52px;
  vertical-align: middle;
}

.d-data-description {
  margin: 10px 32px 10px 0px;
  display: inline-block;
  width: calc(100% - 116px);
  font-size: 14px;
  vertical-align: middle;
}

.d-ranking-title {
  font-size: 13px;
  color: var(--primary-orange);
  text-transform: uppercase;
  margin: 50px 30px 10px;
  width: calc(100% - 60px);
  letter-spacing: 0.8px;
}

.d-ranking-position {
  margin: 10px 0px 10px 32px;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: #10b3ed;
  width: 45px;
  vertical-align: middle;
}

.d-ranking-description {
  margin: 10px 0px;
  display: inline-block;
  width: calc(100% - 161px);
  font-size: 14px;
  vertical-align: middle;
}

.d-ranking-points {
  margin: 10px 32px 10px 0px;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: var(--primary-green);
  width: 52px;
  vertical-align: middle;
  text-align: end;
}

.dashboard-link-container {
  margin: 5px 0px 20px;
  text-align: center;
}

.dashboard-link {
  text-decoration: none;
  color: #ff9162;
  border-bottom: 1px solid #ff9162;
  padding: 2px 0px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 500;
}
.container-social-media-share-button {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 40px;
}
.custom-twitter-login {
  /* On surface 2 */

  background: #ffffff;
  border-radius: 20px;
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 48px;

  /* BOTON 2 */

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #1da1f2;
}
.custom-twitter-login img {
  margin-right: 10px;
}
.dashboard-title {
  /* Overline */

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: left;
  padding-left: 32px;
  letter-spacing: 0.15em;
  text-transform: uppercase;

  /* Gradient */

  color: #868686;
}

.dashboard-subtitle {
  color: var(--primary-blue);
  padding-left: 30px;

  font-family: Poppins;
  font-size: 37px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
  margin-top: 3px;
}

.d-ranking-title-noMargin {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  /* identical to box height */

  letter-spacing: 0.07em;
  text-transform: uppercase;

  /* On surface 1 */

  color: #3c3c3c;

  margin: 10px 31px;
}
.d-medal-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.d-medal-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text-medal {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 23px;
  /* identical to box height, or 192% */

  text-align: center;
}
.bronze {
  color: #b87333;
}
.silver {
  color: #999999;
}
.gold {
  color: #ffcb11;
}
.d-tab-container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  padding: 10px 0px;
  clip-path: inset(0px 0px -15px 0px);
  box-shadow: 1px 1px 15px rgb(0 0 0 / 25%);
  margin-bottom: 20px;
}
.d-ranking-tab {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #00a3dd;

  /* Container */
  width: 30%;
  height: 43px;
  border-radius: 20px;
  border: 2px solid #00a3dd;
  background-color: white;
  margin-left: 1%;
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.d-tab-active {
  color: white;
  background: #00a3dd;
  border-radius: 20px;
}
.division-line {
  /* Grey */
  width: 92%;
  height: 0px;
  border-top: 2px solid #f2f2f2;
  margin: auto;
}
/* .d-ranking-tabLeft {
  display: inline-block;
  text-align: center;
  margin: 10px 0px 10px 28px;
  padding: 10px 0px;
  color: var(--primary-orange);
  width: calc(50% - 28px);
  opacity: 0.6;
} */

/* .d-ranking-tabRight {
  display: inline-block;
  text-align: center;
  margin: 10px 28px 10px 0px;
  padding: 10px 0px;
  color: var(--primary-orange);
  width: calc(50% - 28px);
  opacity: 0.6;
} */

/* .d-r-tab-active {
  opacity: 1;
  border-bottom: 2px solid var(--primary-orange);
} */

.d-s-graphics {
  margin-top: 38px;
}

.d-graphics-container {
  margin: 0px 0px 30px 20px;
  width: calc(100% - 40px);
  overflow-x: scroll;
  white-space: nowrap;
  padding: 10px 0px;
}

.d-graphics-card {
  display: inline-block;
  margin: 0px 10px;
  border-radius: 20px;
  background-color: white;
  width: 320px;
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.25);
}

.d-graphic {
  display: inline-block;
  vertical-align: top;
  padding: 10px 0px;
}

.d-graphic-title {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  text-align: center;
  color: #10b3ed;
  margin: 15px 0px 10px;
}

.d-graphics-card svg {
  font-size: 13px;
}

.d-graphic-description {
  padding: 15px 0px;
  max-height: 160px;
  font-size: 13px;
  width: 90px;
  display: inline-block;
  vertical-align: top;
}

.d-girls {
  color: var(--modulo-6);
}

.d-boys {
  color: var(--modulo-7);
}

.d-notGender {
  white-space: pre-wrap;
  color: var(--modulo-3);
}

.termsAndConditionsText {
  margin: 0px 1rem;
}

.termsAndConditionsText > p,
.privacityPolicy > p {
  margin: 1rem 0px 3rem;
}

.text-link {
  color: var(--profile-header-detail);
  font-weight: 500;
}

.error-modal {
  font-size: 14px;
  padding: 20px;
  text-align: center;
  width: calc(100% - 100px);
  z-index: 102;
  background-color: var(--primary-orange);
  border-radius: 20px;
  /*Positioning*/
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0%);
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

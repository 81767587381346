.LandingPage {
  text-align: center;
  max-width: 800px;
  height: 100vh;
  overflow-y: hidden;
}
.exclamationMark-container {
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.logo-container {
  padding-top: 35px;
  /* height: 115px; */
  /* padding: 0px 0px 30px; */
}
.inputs-and-login-container {
  padding-top: 80px;
  background-image: url("../../assets/images/curveBlue.svg");
  background-position-x: center;
  background-size: cover;
}
.laberinfo-description {
  margin: 0px 40px 40px;
  font-size: 14px;
  font-weight: 500;
}
.login-footer {
  background: #10b3ed;
  border-color: #10b3ed;
  height: calc(100vh - 661px);
  background-image: url("../../assets/images/curveBlue.svg");
  background-position-x: center;
  background-size: cover;
}
.landing-button-play {
  color: var(--primary-green) !important;
  border: 2px solid var(--primary-green) !important;
  padding: 11px 62px !important;
  margin-top: 40px !important;
  margin-bottom: 0px !important;
}
.landing-button {
  margin-top: 25px;
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 6px;
  padding: 15px 40px;
  background-color: var(--primary-green);
  border-radius: 20px;
  border: none;
  outline: none !important;
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
}
.button-ranking {
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 43px;
  width: 205px;
  cursor: pointer;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  border-radius: 20px;
  border: 2px solid #ffffff;

  color: white;
}
.center-button {
  display: flex;
  flex-direction: column;
  padding-top: 25%;
  align-items: center;
}
@media (max-height: 570px) {
  .center-button {
    padding-top: 15%;
  }
}
.modal-laberinfo-description {
  margin: 40px 20px 0px;
  text-align: initial;
  height: 410px;
  overflow-y: auto;
}

.mld-content {
  height: 410px;
}

.lb-disabled {
  opacity: 0.5;
}

.landing-htpButton {
  color: var(--primary-orange);
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 11px 40px;
  background: white;
  border: solid 2px var(--primary-orange);
  border-radius: 100px;
  outline: none !important;
  /*Shadows for every platform*/
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  margin-top: 18px;
  margin-bottom: 25px;
}

.landing-footer {
  background-image: url("../../assets/images/curveBlue.svg");
  background-position-x: center;
  background-size: cover;
  height: calc(100vh - 347px);
}

.footer-button {
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  background: none;
  padding: 11px 40px;
  border: solid 2px white;
  border-radius: 100px;
  outline: none !important;
  margin-top: calc(30px + 10%);
}

@media only screen and (max-height: 620px) {
  .footer-button {
    margin-top: calc(20px + 7%);
  }
}

.reaccion-logo {
  position: absolute;
  bottom: 17px;
  left: 50%;
  transform: translate(-50%, 0%);
}

/*Login*/
.LoginPage {
  text-align: center;
  max-width: 800px;
}

.landing-input {
  color: white;
  outline: none;
  width: 100%;
  padding: 4px 0px;
  border: none;
  border-bottom: 2px solid;
  letter-spacing: 1.2px;
  font-size: 16px;
  background: none;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.5;
}

.landing-input-blue {
  color: #10b3ed;
  outline: none;
  width: 100%;
  padding: 4px 0px;
  border: none;
  border-bottom: 2px solid;
  letter-spacing: 1.2px;
  font-size: 16px;
  background: none;
}
.landing-input-blue::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #10b3ed;
  opacity: 0.5;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #10b3ed;
  opacity: 0.5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #10b3ed;
  opacity: 0.5;
}

.inputs-container {
  margin: 20px 0px 40px;
}

.input-password {
  margin-top: 40px;
}
.input-password-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  color: white;
  cursor: pointer;
}
.register-link-container {
  margin: 20px 0px 25px;
}

.register-link {
  text-decoration: none;
  color: white;
  border-bottom: 1px solid white;
  padding: 2px 0px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;
}

.register-link-separator {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  width: 1px;
  margin: 0px 12px;
  background-color: white;
}

.outline-button {
  color: var(--primary-blue);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  background: none;
  padding: 11px 30px;
  border: solid 2px var(--primary-blue);
  border-radius: 100px;
  outline: none !important;
}

.google-button {
  border: none;
  background: #ffffff;
  border-radius: 20px !important;
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 44px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #bb001b;
  cursor: pointer;
  filter: drop-shadow(1px 1px 15px rgba(0, 0, 0, 0.25));
}
.google-button img {
  margin-right: 20px;
}

.foco-button {
  border: none;
  background: #ffffff;
  border-radius: 20px !important;
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 44px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #04a8fa;
  cursor: pointer;
}

.foco-button img {
  margin-right: 20px;
}

.black-button {
  color: black;
  border-color: black;
}

.facebook-button {
  color: #1778f2;
  border-color: #1778f2;
  width: 260px;
}

.login-reaccion-logo,
.register-reaccion-logo {
  margin: 18px 0px 15px;
  /* height: 85px; */
}

@media only screen and (min-height: 659px) {
  .login-reaccion-logo {
    position: absolute;
    bottom: 17px;
    left: 50%;
    transform: translate(-50%, 0%);
    margin: 0px;
  }
}

@media only screen and (max-height: 555px) {
  .reaccion-logo {
    display: none;
  }
}

.back-with-bg {
  border-radius: 20px;
  background-color: white;
}

.login-error-modal {
  font-size: 14px;
  padding: 20px;
  text-align: center;
  width: calc(100% - 100px);
  z-index: 101;
  background-color: var(--primary-orange);
  border-radius: 20px;
  /*Positioning*/
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0%);
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

.login-error-focomodal {
  font-size: 14px;
  padding: 20px;
  text-align: center;
  width: calc(100% - 100px);
  z-index: 101;
  color: #fff;
  background-color: #ff9d00;
  border-radius: 20px;
  /*Positioning*/
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0%);
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

.white-button {
  color: #fff;
  border-color: white;
  background-color: #04a8fa;
}

/*Register*/

.RegisterPage {
  text-align: center;
  max-width: 800px;
}

.input-register {
  margin-bottom: 40px;
  font-size: 13px;
  padding: 2px 0px;
}

.input-less-margin {
  margin-bottom: 25px !important;
}

@media only screen and (min-height: 780px) {
  .register-reaccion-logo {
    position: absolute;
    bottom: 17px;
    left: 50%;
    transform: translate(-50%, 0%);
    margin: 0px;
  }
}

.input-age {
  margin-left: 50px;
  width: calc(50% - 70px);
  margin-right: 20px;
}

.input-gender {
  margin-left: 20px;
  width: calc(50% - 70px);
  margin-right: 50px;
}

.input-option {
  color: #10b3ed;
  background-color: white;
}

.my-facebook-button-class {
  /* Container */
  border: none;
  background: #ffffff;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 44px;

  /* Content */
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #1778f2;

  filter: drop-shadow(1px 1px 15px rgba(0, 0, 0, 0.25));
}
.my-facebook-button-class img {
  margin-right: 10px;
}
.social-media-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.social-media-login-container > * {
  margin-bottom: 7px;
}

.tw-button {
}
.twitter-icon {
  background-color: #1baee4;
  background-image: url("https://i.imgur.com/sO5Y2aG.png");
  display: block;
  float: left;
  width: 28px;
  height: 28px;
  margin: -1px;
  border-radius: 3px;
  /* box-shadow: inset 0 1px 3px #333; */
}
.tw-container {
  background: #1da1f2;
  color: white;
  padding: 9px 24px;
  border-radius: 4rem;
  border: white;
  font-size: 15px !important;
  cursor: pointer;
  /* transition(color .3s, background-color, .4s); */
}
@media (max-height: 640px) {
  .top3-institution {
    display: none;
  }
  .line-divisory {
    display: none;
  }
}
.top3-institution {
  color: white;
  max-width: 265px;
  margin: auto;
  margin-top: 10px;
  font-family: "Poppins", sans-serif !important;
}
.top3-institution .title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;

  /* Accent */

  color: #ff9162;
}

.top3-institution ol {
  list-style-type: none;
  padding: 0;
  /* Setup the counter. */
  counter-reset: yourVariableName;
}
.top3-institution li {
  margin-top: 5px;
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 23px;
  /* or 192% */

  color: #000000;
}
.top3-institution li div {
  text-align: initial;
}
.top3-institution img {
  margin-right: 12px;
}
.top3-institution li:before {
  /* Advance the number. */
  counter-increment: yourVariableName;
  /* Use the counter number as content. */
  content: counter(yourVariableName) "   ";
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  margin-right: 10px;
  /* or 164% */

  /* Gradient */

  color: #10b3ed;
}
.line-divisory {
  width: 90%;
  margin: auto;
  height: 0;
  background: linear-gradient(180deg, #f2f2f2 0%, #f2f2f2 100%);
  /* Grey */
  border-top: 2px solid #f2f2f2;
}
.brief-description-container {
  width: 80%;
  margin: auto;
  margin-bottom: 21px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  /* or 164% */

  text-align: center;

  /* On surface 1 */

  color: #3c3c3c;
}

.input-login::placeholder {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  color: #51c0ff;
}

.input-login:focus-visible {
  outline: none;
}

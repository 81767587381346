.header-share-certificate {
  color: white;
  width: 100%; /* EXTRA 2 */

  background: #00acf1;
  height: 17vh;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.15em;
  text-transform: uppercase;

  color: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.header-share-certificate #name {
  /* Subtitulo */

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.07em;
  text-transform: uppercase;

  /* On surface 2 */

  color: #ffffff;
}
.header-share-certificate #title-container {
  margin-left: 40px;
}
.blue-line-separator {
  border-bottom: 1px solid rgba(147, 242, 238, 0.5);
}
.body-share-certificate #title-container {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  margin: -20px 0;
  color: #3c3c3c;
  padding: 20px;
}
.body-share-certificate #medals {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px;
}
#id .medals {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* ---------------------- */

.certificate-table #row {
  justify-content: space-between;
  padding: 15px 15%;
  display: flex;
  flex-direction: row;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #000000;
}

.certificate-table #title-row {
  /* Overline */

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #c2c2c2;
}
.button-share-certificate {
  max-width: 180px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 43px;
  background: linear-gradient(180deg, #ffa026 0%, #ff9d21 100%);
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: #ffffff;

  margin-top: 40px;
}
.button-download-certificate {
  max-width: 180px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 11px 43px;
  //border: 1px solid #58cdf7;
  box-sizing: border-box;
  border-radius: 20px;
  margin-top: 20px;
  cursor: pointer;
}
.button-download-certificate {
  text-decoration: none;
  color: #58cdf7;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.box-green {
  //border: 4px #0ecfa4 solid;
  background-color: #fff;
  margin: 0px 14px;
  padding-bottom: 10%;
  border-bottom: none;
  margin-bottom: -31px;
}

.center-monstruito {
  margin-bottom: -3px;
  display: flex;
  justify-content: center;
  margin-top: 14px;
}
.bottom-height {
  height: calc(100vh - 584px);
}
@media (max-height: 600px) {
  .bottom-height {
    height: calc(100vh - 520px) !important;
  }
}
.medal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.lp-back2 {
  position: fixed;
  top: 23px;
  left: 17px;
}
@media only screen and (min-width: 800px) {
  .lp-back2 {
    left: calc(50% - 377.5px);
  }
}
.blue-line-separator-row {
  border-bottom: 1px solid #eef5ff;
  width: 95%;
  margin: auto;
}

.lq-content {
  text-align: center;
  padding-top: 10px;
  height: calc(100vh - 10px);
  background: #ffffff;
}

.lq-lvl1 {
  --level-color: var(--modulo-1);
  /*--level-bg: url('../../assets/images/questions/questionBackground1.svg');*/
  --level-close: url("../../assets/images/interface/closeModal.svg");
}

.lq-lvl2 {
  --level-color: var(--modulo-2);
  /*--level-bg: url('../../assets/images/questions/questionBackground2.svg');*/
  --level-close: url("../../assets/images/interface/closeModal2.svg");
}

.lq-lvl3 {
  --level-color: var(--modulo-3);
  /*--level-bg: url('../../assets/images/questions/questionBackground3.svg');*/
  --level-close: url("../../assets/images/interface/closeModal3.svg");
}

.lq-lvl4 {
  --level-color: var(--modulo-4);
  /*--level-bg: url('../../assets/images/questions/questionBackground4.svg');*/
  --level-close: url("../../assets/images/interface/closeModal4.svg");
}

.lq-lvl5 {
  --level-color: var(--modulo-5);
  /*--level-bg: url('../../assets/images/questions/questionBackground5.svg');*/
  --level-close: url("../../assets/images/interface/closeModal5.svg");
}

.lq-lvl6 {
  --level-color: var(--modulo-6);
  /*--level-bg: url('../../assets/images/questions/questionBackground6.svg');*/
  --level-close: url("../../assets/images/interface/closeModal6.svg");
}

.lq-lvl7 {
  --level-color: var(--modulo-7);
  /*--level-bg: url('../../assets/images/questions/questionBackground7.svg');*/
  --level-close: url("../../assets/images/interface/closeModal7.svg");
}

.lq-timer {
  position: absolute;
  text-align: start;
  top: 24px;
  left: 15px;
  color: var(--level-color);
  font-size: 24px;
  font-weight: 500;
  display: none;
}

@media only screen and (min-width: 800px) {
  .lq-timer {
    left: calc(50% - 380px);
  }
}

.lq-close {
  position: absolute;
  top: 24px;
  right: 15px;
  height: 29px;
  width: 29px;
  background-image: var(--level-close);
  background-repeat: no-repeat;
  background-size: contain;
}

@media only screen and (min-width: 800px) {
  .lq-close {
    left: calc(50% + 356px);
  }
}

.lq-title {
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 1.8px;
  color: var(--level-color);
  text-transform: uppercase;
  display: block;
  margin: 17px 50px;
  font-family: "Poppins";
  font-style: normal;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #3c3c3c;
}

.lq-question {
  font-size: 18px;
  font-weight: 500;
  height: 165px;
  /*border: 4px solid var(--level-color);
    border-radius: 10px;*/
  display: flex;
  margin: 25px auto 10px;
  padding: 15px;
  width: 80%;
  align-items: flex-start;
  justify-content: center;
  color: var(--level-color);
}

@media screen and (max-width: 800px) {
  .lq-question {
    height: 0;
    /*border: 4px solid var(--level-color);
    border-radius: 10px;*/
  }
}

.lq-question > span {
  text-align: center;
  z-index: 91;
}

/*background*/
.lq-body {
  background-image: var(--level-bg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 287px);
}

.lq-answer-progress {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background: #fdfdfd;
  border-radius: 10px;
  height: 308px;
}

@media only screen and (min-width: 801px) {
  .lq-answer-progress {
    left: calc(50% - 380px);
    right: calc(50% - 380px);
  }
}

/*Real content*/
.lq-answer-content {
  position: absolute;
  bottom: 30px;
  width: 100%;
  max-width: 800px;
}

.lq-answer {
  font-size: 14px;
  height: 64px;
  border: 2px solid var(--level-color);
  background-color: white;
  border-radius: 20px;
  display: flex;
  margin: 14px auto;
  padding: 0px 15px;
  width: 80%;
  max-width: 640px;
  align-items: center;
  justify-content: center;
  /*Shadows for every platform*/
  /*-webkit-box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.25);
    box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.25);*/
}

@media only screen and (min-width: 800px) {
  .lq-answer {
    margin: 14px 63px;
  }
}

.lq-answer:hover {
  background-color: #7dce30;
  color: #fff;
}

.lq-progress {
  position: absolute;
  bottom: 18px;
  margin: auto;
  height: 6px;
  width: calc(100% - 40px);
  max-width: 760px;
  margin: 0 20px;
  border-radius: 3px;
  background-color: rgb(155, 155, 155, 0.5);
  z-index: 99;
}

.lq-bar {
  background-color: #7dce30aa;
  height: 100%;
}

.lq-modal {
  padding: 20px;
  z-index: 105;
  background-color: white;
  border-radius: 20px;
  max-width: 260px;
  color: var(--level-color);
  /*Centering*/
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

/*@media only screen and (min-width: 800px) {
    .lq-modal {
        left: 400px;
    }
}*/

.lq-modal-title {
  text-align: start;
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 25px);
  font-size: 18px;
  font-weight: 500;
}

.lq-modal-close {
  display: inline-block;
  vertical-align: middle;
  height: 25px;
  width: 25px;
  background-image: var(--level-close);
  background-repeat: no-repeat;
  background-size: contain;
}

.lq-modal-body {
  text-align: start;
  margin-top: 20px;
  margin-bottom: 25px;
}

.lq-button {
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 8px 25px;
  background: var(--level-color);
  border-radius: 100px;
  border: 1px solid var(--level-color);
  outline: none !important;
  width: 190px;
  margin-bottom: 15px;
}

.lq-screenAnswer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: white;
  max-width: 800px;
  height: 100vh;
  z-index: 100;
}

@media only screen and (min-width: 800px) {
  .lq-screenAnswer {
    left: calc(50% - 400px);
  }
}

.lq-correct {
  --sa-background: url("../../assets/images/questions/correct.svg");
  --sa-height: calc(100vh - 270px);
  --sa-color: var(--modulo-2);
}

.lq-incorrect {
  --sa-background: url("../../assets/images/questions/incorrect.svg");
  --sa-height: calc(100vh - 270px);
  --sa-color: var(--modulo-3);
}

.lq-sa-background {
  padding-top: 10px;
  height: var(--sa-height);
  width: 100%;
  /*background-image: var(--sa-background);*/
  /*--level-close: url('../../assets/images/interface/closeWhite.svg');*/
  background-size: cover;
  background-position: bottom;
  color: var(--level-color);
}

.lq-sa-background > .lq-title {
  padding-top: 17px;
  margin-top: 0px;
}

.lq-sa-answer {
  font-size: 37px;
  font-weight: 600;
  margin-top: 35px;
  color: var(--sa-color);
}

.secondary {
  color: var(--level-color);
  background-color: white;
}

.lq-sa-image-holder {
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
  border-radius: 10px;
  height: calc(var(--sa-height) - 173px - 5vh);
  background-color: rgba(255, 255, 255, 0.3);
  align-items: center;
}

.lq-sa-image {
  max-height: 100%;
  margin: auto;
  max-width: 90%;
  margin: 20px;
}

.lq-sa-text {
  display: flex;
  align-items: center;
  height: 150px;
  width: calc(100% - 60px);
  max-width: 770px;
  position: absolute;
  bottom: 110px;
  padding: 0px 30px;
  font-weight: 500;
}

.lq-sa-text > span {
  width: 100%;
}

.lq-sa-button {
  position: absolute;
  bottom: 42px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: linear-gradient(#ff9162, #ff7e46);
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 6px;
  padding: 16px 50px;
  border-radius: 20px;
  border: none;
  outline: none !important;
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.4);
}

.lq-incorrect > .lq-sa-button,
.lq-fun-fact > .lq-sa-button {
  background: #ff9162;
}

.lq-fun-fact {
  --sa-height: calc(100vh - 240px);
}

.lq-sa-image-ff {
  max-height: 90%;
  margin: auto;
  max-width: 90%;
}

.move-pet {
  bottom: 182px !important;
}

.lq-girl {
  position: absolute;
  z-index: 90;
  bottom: 311px;
  left: 50%;
  width: 200px;
  height: auto;
  transform: translate(-50%, 0px);
}

@media only screen and (max-width: 800px) {
  .lq-girl {
    position: absolute;
    z-index: 90;
    bottom: 315px;
    left: 50%;
    width: 165px;
    height: auto;
    transform: translate(-50%, 0px);
  }
}

.lq-pet-0 {
  position: absolute;
  z-index: 90;
  bottom: 264px;
  left: 50%;
  height: 100px;
  transform: translate(-50%, 0px);
}
.lq-pet-1 {
  position: absolute;
  z-index: 0;
  bottom: 250px;
  left: 0px;
  height: 150px;
}
.lq-pet-2 {
  position: absolute;
  z-index: 0;
  bottom: 250px;
  right: 0px;
  height: 150px;
}

.lq-pet-3 {
  position: absolute;
  z-index: 90;
  bottom: 264px;
  left: 50%;
  height: 100px;
  transform: translate(-50%, 0px);
}

@media only screen and (min-width: 800px) {
  .lq-pet-1 {
    left: calc(50% - 400px);
  }

  .lq-pet-2 {
    right: calc(50% - 400px);
  }
}

.lq-finish-container {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}

.lq-finish-title {
  margin-top: 5%;
  font-size: 20px;
  font-weight: 500;
}

.lq-finish-title > h1 {
  margin: 0px;
  color: var(--level-color);
  font-size: 37px;
  font-weight: 600;
}

.lq-finish-stats {
  color: var(--level-color);
  text-align: start;
  font-size: 20px;
  font-weight: 500;
  padding: 25px 30px;
  width: (90% - 60px);
  min-width: 260px;
}

.lqf-button {
  display: block;
  color: white;
  text-transform: uppercase;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 6px;
  padding: 14px 35px;
  background: var(--level-color);
  border-radius: 20px;
  border: 1px solid var(--level-color);
  outline: none !important;
  margin: 50px auto 0px;
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.2);
}

.lq-bonus {
  display: block;
  margin: 15px auto 0px;
  background: linear-gradient(#ff9162, #ff7e46);
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 6px;
  padding: 16px 50px;
  border-radius: 20px;
  border: none;
  outline: none !important;
  /*Shadows for every platform*/
  -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.2);
}

.flexBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/*.MuiTypography-h6 {
  color: #10b3ed;
}*/
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #fff;
}
hr {
  border: 1px solid #f2f2f2;
  width: 100%;
}

.container-table {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-x: auto;
}
/*table*/
.MuiPaper-root {
  width: 100%;
  margin-bottom: 24px;
}

.MuiTable-root {
  background-color: white;
}

.MuiMenu-paper {
  width: 2%;
  height: 150px;
}
.MTableToolbar-root-5 {
  display: none !important;
}

.MuiTableCell-alignRight {
  text-align: left !important;
}

.title {
  width: 194px;
  height: 40px;
  margin-top: 30px;
  color: #10b3ed;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 37px;
  line-height: 40px;
}

.sub-title {
  margin-right: 75px;
  color: #00a3dd;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 30px;
}

.select {
  width: 243.05px;
  height: 50px;
  margin-right: 50px;
  padding: 12px 18px;
  border-radius: 5px;
  border: none;
  background: #f2f2f2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  //appearance: none;
}

.mandatory {
  width: 90px;
  height: 23px;
  color: #ff9162;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
}

.select2 {
  width: 243.05px;
  height: 47px;
  //margin-left: 123px;
  margin-right: 21.95px;
  padding: 12px 18px;
  border-radius: 5px;
  border: none;
  //color: #10b3ed;
  background: #f2f2f2;
  //appearance: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
}

.select3 {
  width: 311.05px;
  margin-right: 0;
}

.downloadLink {
  position: relative;
  color: #10b3ed;
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-right: 35px;
}

.downloadLinkIcon {
  position: absolute;
  margin-left: 19px;
}

.first-column {
  width: 444px;
  height: 78px;
  display: flex;
  align-items: center;
  padding: 2px 0px;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.correctAnswer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 18px;
  width: 147px;
  height: 75px;
  background-color: #7dce30;
  border-radius: 10px;
}
.wrongAnswer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 18px;
  width: 147px;
  height: 75px;
  background-color: #ff9162;
  border-radius: 10px;
  //color: #fff;
}

@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  /*colors*/
  --primary-blue: #58cdf6;
  --primary-green: #7dce30;
  --primary-orange: #ffa026;
  --primary-black: #3c3c3c;
  --primary-gray: #ccc;
  --tab-inactive: #00a3dd;
  --profile-header-detail: #93f2ee;
  --modulo-1: #58cdf7;
  --modulo-2: #78dc36;
  --modulo-3: #ff9e30;
  --modulo-4: #fdc52f;
  --modulo-5: #00e3d4;
  --modulo-6: #ff78a3;
  --modulo-7: #708fff;
}

audio {
  display: none;
}

.global_bg {
  background-color: #f2f2f2;
}

input,
option,
select {
  font-family: "Poppins", sans-serif;
}

/*App*/
.App {
  background-color: white;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVGhD7dQxSkNBEMbxmX3Z5BUBA2IuYBUEK3tR7+B1goewsPIAHiKIvYV4AItgkUILjYIx7zPvuaVdtljw/2tmmJ3mY2EMAAAAAPAHT3Vrl4vF0Ae9Y6vCYWP6bFar27vR3sON+zqtFCGkujXV8UzBjyT1XbZTxXh6snw9SM/FyBa4Cj5J7S/ZUPLdKymmSRGyBV5LH6ntaDNqpK/nri1HtsAu3bvbW9u3YeV68ujzC/fvbqEQ2Y7WtVQv31/23cK4/dk27Lgezc8LO1pZbQ6WT/XYn2rWSyMAAAAAAAAAAAAAAAAA+N/MfgClL0ePPEMIUgAAAABJRU5ErkJggg==);
  background-size: 30px;
  min-height: 100vh;
  margin: auto;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}

button {
  font-family: "Poppins", sans-serif !important;
  line-height: 1.15;
}

.button-disabled {
  opacity: 0.5;
}

/*Tab*/
.Tab {
  text-align: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: 800px;
  background-color: #58cdf6 !important;
  background-color: var(--primary-blue) !important;
  color: #00a3dd !important;
  color: var(--tab-inactive) !important;
  height: 80px;
  justify-content: space-around;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  /*Shadows for every platform*/
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

.tab-link {
  width: 33%;
  display: block;
  padding-top: 11px;
  text-decoration: none !important;
  color: #00a3dd !important;
  color: var(--tab-inactive) !important;
}

.tb-active {
  color: white !important;
}

.tab-link:active {
  background-color: #58cdf6 !important;
  background-color: var(--primary-blue) !important;
}

/*Home*/
.Home {
  align-items: center;
  max-width: 800px;
  height: calc(100vh - 80px);
  background-color: #e9f4ff;
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  max-width: 800px;
  z-index: 101;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

@media only screen and (min-width: 800px) {
  .overlay {
    left: calc(50% - 400px);
  }
}

.home-mykure {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 120px;
  height: auto;
}

.home-monai {
  position: absolute;
  bottom: 200px;
  right: 50px;
  width: 120px;
  height: auto;
}

.home-child {
  position: absolute;
  //bottom: 210px;
  right: 550px;
  width: 80px;
  top: 20px;
  height: auto;
}

@media only screen and (max-width: 500px) {
  .home-child {
    display: none;
  }
}

@media only screen and (min-width: 800px) {
  .home-mykure {
    left: calc(50% - 350px);
  }
  .home-monai {
    right: calc(50% - 350px);
  }
  .home-child {
    left: calc(50% - 302px);
  }
}

@media only screen and (max-width: 550px) {
  .home-mykure,
  .home-monai {
    display: none;
  }
}

.home-modal {
  padding: 27px 20px;
  z-index: 102;
  height: 546px;
  width: 270px;
  background-color: white;
  border-radius: 20px;
  /*Centering*/
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /*Shadows for every platform*/
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

.level-modal {
  padding: 20px 20px;
  z-index: 102;
  background-color: white;
  color: #58cdf6;
  color: var(--primary-blue);
  border-radius: 15px;
  /*Centering*/
  position: absolute;
  margin-right: -50%;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /*Shadows for every platform*/
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.2);
}

.lm-body {
  padding-top: 15px;
  padding-bottom: 17px;
  font-size: 14px;
}

.lmodal-1 {
  color: #58cdf7 !important;
  color: var(--modulo-1) !important;
}
.lmodal-2 {
  color: #78dc36 !important;
  color: var(--modulo-2) !important;
}
.lmodal-3 {
  color: #ff9e30 !important;
  color: var(--modulo-3) !important;
}
.lmodal-4 {
  color: #fdc52f !important;
  color: var(--modulo-4) !important;
}
.lmodal-5 {
  color: #00e3d4 !important;
  color: var(--modulo-5) !important;
}
.lmodal-6 {
  color: #ff78a3 !important;
  color: var(--modulo-6) !important;
}
.lmodal-0 {
  color: #708fff !important;
  color: var(--modulo-7) !important;
}

.modal-closeButton {
  display: inherit;
  align-self: end;
  margin-left: auto;
}

.modal-header {
  display: flex;
  font-weight: 500;
  font-size: 18px;
}

.home-modal > .modal-header {
  color: #ffa026;
  color: var(--primary-orange);
}

.small-header {
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
}

.modal-content {
  height: 440px;
}

.modal-content-page {
  display: inline-block;
  color: #ffa026;
  color: var(--primary-orange);
  margin: 0px;
  width: 40px;
  font-weight: 600;
  font-size: 37px;
  vertical-align: top;
}

.modal-description {
  text-align: justify;
  padding-top: 20px;
  display: inline-block;
  width: 230px;
  height: 86px;
  font-size: 14px;
}

.modal-image-container {
  text-align: center;
  margin-top: 15px;
}

.modal-image {
  height: 295px;
  /*Shadows for every platform*/
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
}

.lm-footer {
  text-align: center;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.lm-button {
  color: white;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 8px 25px;
  background: white;
  border-radius: 100px;
  border: none;
  outline: none !important;
  /*Shadows for every platform*/
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.lm-certificate {
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 6px 23px;
  background: white;
  border-radius: 100px;
  border: none;
  outline: none !important;
  margin: auto;
  margin-top: 12px;
  width: 180px !important;
}

.locked {
  background-color: #ccc;
  background-color: var(--primary-gray);
}

.lmb-1 {
  background-color: #58cdf7;
  background-color: var(--modulo-1);
}

.lmc-1 {
  border: 2px solid #58cdf7;
  border: 2px solid var(--modulo-1);
  color: #58cdf7;
  color: var(--modulo-1);
}

.lmb-2 {
  background-color: #78dc36;
  background-color: var(--modulo-2);
}

.lmc-2 {
  border: 2px solid #78dc36;
  border: 2px solid var(--modulo-2);
  color: #78dc36;
  color: var(--modulo-2);
}

.lmb-3 {
  background-color: #ff9e30;
  background-color: var(--modulo-3);
}

.lmc-3 {
  border: 2px solid #ff9e30;
  border: 2px solid var(--modulo-3);
  color: #ff9e30;
  color: var(--modulo-3);
}

.lmb-4 {
  background-color: #fdc52f;
  background-color: var(--modulo-4);
}

.lmc-4 {
  border: 2px solid #fdc52f;
  border: 2px solid var(--modulo-4);
  color: #fdc52f;
  color: var(--modulo-4);
}

.lmb-5 {
  background-color: #00e3d4;
  background-color: var(--modulo-5);
}

.lmc-5 {
  border: 2px solid #00e3d4;
  border: 2px solid var(--modulo-5);
  color: #00e3d4;
  color: var(--modulo-5);
}

.lmb-6 {
  background-color: #ff78a3;
  background-color: var(--modulo-6);
}

.lmc-6 {
  border: 2px solid #ff78a3;
  border: 2px solid var(--modulo-6);
  color: #ff78a3;
  color: var(--modulo-6);
}

.lmb-0 {
  background-color: #708fff;
  background-color: var(--modulo-7);
}

.lmc-0 {
  border: 2px solid #708fff;
  border: 2px solid var(--modulo-7);
  color: #708fff;
  color: var(--modulo-7);
}

.arrow-container {
  display: inline-block;
  padding-top: 7px;
  width: 75px;
}

.empty-button {
  display: inline-block;
  width: 103.4px;
}

.modal-footer {
  text-align: center;
  height: 108px;
}
.modal-okButton {
  vertical-align: top;
  color: #ffa026;
  color: var(--primary-orange);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 10px 40px;
  background: white;
  border: solid 2px #ffa026;
  border: solid 2px var(--primary-orange);
  border-radius: 100px;
  outline: none !important;
  /*Shadows for every platform*/
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
}

.modal-pages {
  margin-top: 15px;
  text-align: center;
}

.modal-page {
  display: inline-block;
  height: 6px;
  width: 6px;
  background-color: #58cdf6;
  background-color: var(--primary-blue);
  opacity: 0.3;
  border-radius: 3px;
  margin: 0px 11px;
}

.mp-enabled {
  opacity: 1;
}

.levels-container {
  width: 300px;
  height: 450px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.required-data {
  position: absolute;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  max-width: 800px;
  height: 100vh;
  overflow-y: hidden;
  background-color: white;
  z-index: 100;
  text-align: center;
}

@media only screen and (min-width: 800px) {
  .required-data {
    left: calc(50% - 400px);
  }
}

.rd-title {
  padding-top: 10px;
}

.rd-title-fullWidth {
  font-weight: 500;
  font-size: 19px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  margin: 17px 20px;
}

.rd-question {
  margin: 55px 0px 20px;
  font-size: 20px;
  font-weight: 500;
}

.rd-body {
  height: calc(100vh - 158px);
  background-image: url(/static/media/curveBlueLarge.f9acb856.svg);
  background-position-x: center;
  background-size: cover;
  padding-top: 80px;
  color: white;
}

.rd-input {
  display: inline-block;
  color: white;
  outline: none;
  width: calc(100% - 100px);
  padding: 4px 0px;
  border: none;
  border-bottom: 2px solid white;
  letter-spacing: 1.6px;
  font-size: 12px;
  background: none;
  margin-bottom: 40px;
}

/*For input autocomplete (institutions)*/
.css-yk16xz-control,
.css-1pahdxg-control {
  background: none !important;
  border: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  min-height: 0px !important;
  margin: 0px 2px;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.css-1wa3eu0-placeholder,
.css-1uccc91-singleValue {
  color: white !important;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  color: white !important;
  padding: 0px !important;
  height: 16px;
  width: 16px;
  margin-bottom: 4px !important;
  margin-right: -1px !important;
}

.css-2b097c-container {
  padding: 0px 0px 2px !important;
  text-align: left !important;
  text-align: initial !important;
}

.css-b8ldur-Input,
.css-g1d714-ValueContainer {
  padding: 0px !important;
  color: white !important;
}

.rd-input > span {
  background: none !important;
}

.rd-input > div {
  color: #10b3ed !important;
}

.css-2613qy-menu {
  border-radius: 0px !important;
}
/*###*/

.rd-input-uppercase {
  text-transform: uppercase;
}

.rd-text {
  margin: 0px 40px 60px;
  text-align: center;
}

.rd-button {
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 6px;
  padding: 15px 40px;
  background-color: #7dce30;
  background-color: var(--primary-green);
  border-radius: 20px;
  border: none;
  outline: none !important;
  /*Shadows for every platform*/
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 5px;
}

.reaccion-logo {
  position: absolute;
  bottom: 22px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
}

@media only screen and (max-height: 560px) {
  .reaccion-logo {
    display: none;
  }
}

/*Iterable levels*/
.margine-0 {
  --extra-top: 0px;
}
.margine-1 {
  --extra-top: 203px;
}
.margine-2 {
  --extra-top: 406px;
}
.margine-3 {
  --extra-top: 609px;
}
.margine-4 {
  --extra-top: 812px;
}
.margine-5 {
  --extra-top: 1015px;
}
.margine-6 {
  --extra-top: 1218px;
}
.margine-7 {
  --extra-top: 1421px;
}
.margine-8 {
  --extra-top: 1624px;
}
.margine-9 {
  --extra-top: 1827px;
}
.margine-10 {
  --extra-top: 2030px;
}
.levels-container-iterable {
  position: fixed;
  width: 100%;
  max-width: 800px;
  height: calc(100vh - 80px);
  display: flex;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
}

.level-resto-1 {
  height: 77px;
  position: absolute;
  top: calc(20px + var(--extra-top));
  left: calc(50% - 7px);
  -webkit-transform: translate(-50%, 0px);
          transform: translate(-50%, 0px);
  /*This allows scroll 20px under last lvl rendered*/
  margin-bottom: 20px;
}

.level-resto-2 {
  height: 86px;
  position: absolute;
  top: calc(94px + var(--extra-top));
  left: calc(50% + 28px);
  -webkit-transform: translate(-50%, 0px);
          transform: translate(-50%, 0px);
  /*This allows scroll 20px under last lvl rendered*/
  margin-bottom: 20px;
}

.level-resto-0 {
  height: 74px;
  position: absolute;
  top: calc(161px + var(--extra-top));
  left: calc(50% - 21px);
  -webkit-transform: translate(-50%, 0px);
          transform: translate(-50%, 0px);
  /*This allows scroll 20px under last lvl rendered*/
  margin-bottom: 20px;
}

.circle-resto-1 {
  height: 70px;
  position: absolute;
  top: calc(28px + var(--extra-top));
  left: calc(50% - 87px);
}

.circle-resto-2 {
  height: 70px;
  position: absolute;
  top: calc(112px + var(--extra-top));
  left: calc(50% + 36px);
}

.circle-resto-0 {
  height: 70px;
  position: absolute;
  top: calc(151px + var(--extra-top));
  left: calc(50% - 91px);
}

.circle-number {
  font-size: 24px;
  font-weight: 700;
  color: white;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 68px;
  text-align: center;
  height: 67px;
}

.ribbon-resto-1 {
  position: absolute;
  top: calc(50px + var(--extra-top));
  left: calc(50% - 20px);
}

.ribbon-resto-2 {
  position: absolute;
  top: calc(122px + var(--extra-top));
  left: calc(50% + 14px);
}

.ribbon-resto-0 {
  position: absolute;
  top: calc(184px + var(--extra-top));
  left: calc(50% - 27px);
}

.locked-resto-1 {
  height: 70px;
  position: absolute;
  top: calc(50px + var(--extra-top));
  left: calc(67% - 87px);
}

.locked-resto-2 {
  height: 70px;
  position: absolute;
  top: calc(120px + var(--extra-top));
  left: calc(46% + 36px);
}

.locked-resto-0 {
  height: 70px;
  position: absolute;
  top: calc(185px + var(--extra-top));
  left: calc(66% - 91px);
}

.play-resto-1 {
  height: 70px;
  position: absolute;
  top: calc(30px + var(--extra-top));
  left: calc(59% - 87px);
}

.play-resto-2 {
  height: 70px;
  position: absolute;
  top: calc(100px + var(--extra-top));
  left: calc(25% + 36px);
}

.play-resto-0 {
  height: 70px;
  position: absolute;
  top: calc(173px + var(--extra-top));
  left: calc(57% - 91px);
}

@media screen and (min-width: 500px) {
  .level-resto-1 {
    left: calc(50% - 20px);
  }
  .level-resto-2 {
    left: calc(50% - -10px);
  }
  .level-resto-0 {
    left: calc(50% - 38px);
  }
  .ribbon-resto-1 {
    left: calc(50% - 40px);
  }
  .ribbon-resto-2 {
    left: calc(50% - -2px);
  }
  .ribbon-resto-0 {
    left: calc(50% - 44px);
  }
  .circle-resto-1 {
    left: calc(50% - 107px);
  }
  .circle-resto-2 {
    left: calc(50% - -18px);
  }
  .circle-resto-0 {
    left: calc(50% - 108px);
  }
  .locked-resto-1 {
    /*height: 70px;
    position: absolute;
    top: calc(50px + var(--extra-top));*/
    left: calc(56% - 87px);
  }

  .locked-resto-2 {
    /*height: 70px;
    position: absolute;
    top: calc(120px + var(--extra-top));*/
    left: calc(46% + 36px);
  }

  .locked-resto-0 {
    /*height: 70px;
    position: absolute;
    top: calc(185px + var(--extra-top));*/
    left: calc(55% - 91px);
  }
  .play-resto-1 {
    height: 70px;
    position: absolute;
    top: calc(30px + var(--extra-top));

    left: calc(52% - 87px);
  }

  .play-resto-2 {
    height: 70px;
    position: absolute;
    top: calc(100px + var(--extra-top));

    left: calc(35% + 36px);
  }

  .play-resto-0 {
    height: 70px;
    position: absolute;
    top: calc(173px + var(--extra-top));

    left: calc(51% - 91px);
  }
}

/*#########*/

.how-to-play-button {
  position: fixed;
  bottom: 98px;
  left: 10px;
}
.share-button {
  position: fixed;
  bottom: 85px;
  right: 10px;
}
.trigger-sound-button {
  position: fixed;
  bottom: 85px;
  right: 10px;
  top: 18px;
  height: 75px;
}

@media only screen and (min-width: 800px) {
  .how-to-play-button {
    left: calc(50% - 390px);
  }

  .trigger-sound-button {
    left: calc(50% + 302px);
  }
  .share-button {
    left: calc(50% + 302px);
  }
}

.colorPrimary {
  color: #58cdf6;
  color: var(--primary-blue);
  text-align: center;
}

/*Profile*/
/*header*/
.profile-header {
  background: linear-gradient(
    0deg,
    rgba(16, 179, 237, 1) 0%,
    rgba(84, 204, 246, 1) 100%
  );
  color: #3c3c3c;
  color: var(--primary-black);
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 500;
  height: 138px;
}

.ph-avatar-container {
  min-width: 40px;
  max-width: 120px;
  display: inline-block;
  width: 25%;
  height: 98px;
  margin: 20px 16px;
  /*centering image*/
  position: relative;
}

.ph-avatar {
  border-radius: 100px;
  border: 2px solid #93f2ee;
  border: 2px solid var(--profile-header-detail);
  /*centering image*/
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  /*ignore borders*/
  -webkit-transform: translate(-2px, 0px);
          transform: translate(-2px, 0px);
}

.ph-details {
  color: white;
  display: inline-block;
  margin: 20px 16px;
  height: 98px;
  /*on inline-block, set vertical align to top for avoid the next blocks to move down*/
  vertical-align: top;
}

@media only screen and (min-width: 480px) {
  .ph-details {
    width: calc(100% - 184px);
  }
}

@media only screen and (max-width: 480px) {
  .ph-details {
    width: calc(75% - 64px);
  }
}
.ph-details-buttons {
  padding-top: 30px;
  align-content: space-between;
}

.ph-button-content {
  display: inline-block;
  width: calc(100% - 32px);
}

.ph-settings {
  display: inline-block;
  vertical-align: middle;
}

.ph-button {
  color: white;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  max-width: 166px;
  width: calc(100% - 10px);
  min-width: 139px;
  padding: 12px 15px;
  background: none;
  border: solid 2px white;
  border-radius: 100px;
  outline: none !important;
  /*Shadows for every platform*/
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.1);
}

/*tabsd*/
.profile-tabs {
  display: inline-block;
  background-color: #c8fdfb;
  color: #93f2ee;
  color: var(--profile-header-detail);
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  padding: 14px 0px 17px;
  width: 50%;
  border-bottom: solid 2px white;
}

.pt-active {
  color: #00a3dd;
  color: var(--tab-inactive);
  border-bottom: solid 2px #00a3dd !important;
  border-bottom: solid 2px var(--tab-inactive) !important;
}

/*Content*/
.profile-content {
  padding: 0px 15px;
  height: calc(100vh - 270px);
  overflow-y: scroll;
  background-color: white;
}
.medals-profile {
  display: flex;
}
.medals-profile .medal-container {
  margin-right: 20px;
}
.profile-item {
  padding: 20px;
  border-bottom: solid 2px #f2f2f2;
}

.profile-item-title {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  padding-bottom: 12px;
}

.pit-data {
  color: #ffa026 !important;
  color: var(--primary-orange) !important;
}

.pit-achievements {
  color: #ff78a3 !important;
  color: var(--modulo-6) !important;
}

.profile-item-body {
  padding-bottom: 4px;
  /*Cut the word if is too long*/
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

.profile-avatars,
.profile-settings,
.ranking-modal,
.termsAndConditions,
.privacityPolicy {
  padding: 25px 20px;
  z-index: 102;
  height: calc(100vh - 90px);
  width: calc(100% - 80px);
  max-width: 720px;
  background-color: white;
  border-radius: 20px;
  /*Centering*/
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /*Shadows for every platform*/
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

.pa-title {
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  color: #58cdf6;
  color: var(--primary-blue);
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 32px;
  width: calc(100% - 30px);
}

.pa-avatars-scroll {
  height: calc(100vh - 147px);
  overflow-y: scroll;
}

.pa-closeButton {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.pa-avatar-container {
  text-align: center;
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 12px);
  height: 24%;
  /*padding-bottom: calc(50% - 8px);*/
  margin: 6px;
}

.pa-avatar {
  max-width: 100%;
  max-height: 100%;
}

.pa-selected {
  opacity: 0.5;
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

/*Profile settings*/
.ps-scroll {
  height: calc(100vh - 210px);
  overflow-y: auto;
}

.profile-settings-margin {
  margin: 0px 1rem;
}

.ps-input,
.ps-select {
  color: #10b3ed;
  outline: none;
  padding: 4px 0px;
  border: none;
  letter-spacing: 1.2px;
  font-size: 14px;
  background: none;
  width: 100%;
  margin-bottom: 35px;
}

.ps-select__single-value,
.ps-select .css-b8ldur-Input,
.ps-select .css-g1d714-ValueContainer {
  color: #10b3ed !important;
  letter-spacing: 1.2px;
  font-size: 14px;
}

.ps-select__placeholder {
  color: #10b3ed88 !important;
  letter-spacing: 1.2px;
  font-size: 14px;
}

.ps-button-container {
  text-align: center;
}

.ps-button {
  margin-top: 26px;
  color: #58cdf6;
  color: var(--primary-blue);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 10px 40px;
  background: white;
  border: solid 2px #58cdf6;
  border: solid 2px var(--primary-blue);
  border-radius: 100px;
  outline: none !important;
  /*Shadows for every platform*/
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
}

.ps-changePass {
  font-size: 14px;
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  color: #10b3ed;
  padding: 8px 15px;
  border: 2px solid #10b3ed;
  border-radius: 30px;
  margin: 10px 0px 45px;
}
/*#####*/

/*LevelPage*/
.LevelPage {
  height: 100%;
}

.lp-content {
  padding-top: 10px;
  vertical-align: middle;
  height: 100vh;
}

.lp-bg1 {
  background-color: #58cdf7;
  background-color: var(--modulo-1);
}

.lp-bg2 {
  background-color: #78dc36;
  background-color: var(--modulo-2);
}

.lp-bg3 {
  background-color: #ff9e30;
  background-color: var(--modulo-3);
}

.lp-bg4 {
  background-color: #fdc52f;
  background-color: var(--modulo-4);
}

.lp-bg5 {
  background-color: #00e3d4;
  background-color: var(--modulo-5);
}

.lp-bg6 {
  background-color: #ff78a3;
  background-color: var(--modulo-6);
}

.lp-bg0 {
  background-color: #708fff;
  background-color: var(--modulo-7);
}

.lp-back {
  position: fixed;
  top: 23px;
  left: 23px;
  color: white;
}
@media only screen and (min-width: 800px) {
  .lp-back {
    left: calc(50% - 377.5px);
  }
}

.lp-title {
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  margin: 17px 60px;
  color: white;
}

.lp-paraguayoite {
  position: absolute;
  top: 75px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  width: 80%;
  max-width: 320px;
}

.lp-levelAvatar {
  position: absolute;
  top: 90px;
  left: 50%;
  -webkit-transform: translate(-50%, 25%);
          transform: translate(-50%, 25%);
  border-radius: 100px;
  width: 52%;
  max-width: 208px;
}

.lp-startButton {
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 6px;
  padding: 16px 50px;
  background-color: #ffa026;
  background-color: var(--primary-orange);
  border-radius: 20px;
  border: none;
  outline: none !important;
  /*Shadows for every platform*/
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.4);
}

/*@media only screen and (min-width: 800px) {
  .lp-paraguayoite {
    left: 240px;
    transform: translate(0%, 0);
  }
  .lp-levelAvatar {
    left: 296px;
    transform: translate(0%, 25%);
  }
}*/

.lp-spaceForImage {
  margin-top: 91%;
  text-align: center;
}

@media only screen and (min-width: 400px) {
  .lp-spaceForImage {
    margin-top: 364px;
  }
}

.lp-friends {
  color: #7dce30;
  color: var(--primary-green);
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.5px;
  height: 108px;
  background-color: white;
  width: calc(100% - 60px);
  max-width: 740px;
  margin-top: 36px;
  padding: 15px 30px;
  /*Shadows for every platform*/
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
}

.lpf-list {
  overflow-x: scroll;
  margin-top: 16px;
  white-space: nowrap;
}

.lpf-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 80px;
}

.lpf-avatar {
  height: 42px;
  width: 42px;
  border-radius: 30px;
  border: 1px solid #58cdf6;
  border: 1px solid var(--primary-blue);
}

.lpf-name {
  font-size: 12px;
  color: black;
  font-weight: 400;
  white-space: normal;
  word-wrap: break-word;
}

.lp-level1 {
  /*background: linear-gradient(0deg, rgba(16,179,237,1) 0%, rgba(84,204,246,1) 100%);*/
  color: #58cdf7;
  color: var(--modulo-1);
  --paraguayoite: url(/static/media/paraguayoite1.516e4b34.svg);
}
.lpb-1 {
  -webkit-filter: invert(68%) sepia(73%) saturate(465%) hue-rotate(163deg)
    brightness(96%) contrast(100%);
          filter: invert(68%) sepia(73%) saturate(465%) hue-rotate(163deg)
    brightness(96%) contrast(100%);
}

.lp-level2 {
  /*background: linear-gradient(0deg, #6fb52d 0%, #84d637 100%);*/
  color: #78dc36;
  color: var(--modulo-2);
  --paraguayoite: url(/static/media/paraguayoite2.994b40a7.svg);
}
.lpb-2 {
  -webkit-filter: invert(72%) sepia(29%) saturate(1120%) hue-rotate(44deg)
    brightness(98%) contrast(82%);
          filter: invert(72%) sepia(29%) saturate(1120%) hue-rotate(44deg)
    brightness(98%) contrast(82%);
}

.lp-level3 {
  /*background: linear-gradient(0deg, #da8315 0%, #fda738 100%);*/
  color: #ff9e30;
  color: var(--modulo-3);
  --paraguayoite: url(/static/media/paraguayoite3.6f07ae38.svg);
}
.lpb-3 {
  -webkit-filter: invert(72%) sepia(73%) saturate(1985%) hue-rotate(337deg)
    brightness(101%) contrast(107%);
          filter: invert(72%) sepia(73%) saturate(1985%) hue-rotate(337deg)
    brightness(101%) contrast(107%);
}

.lp-level4 {
  /*background: linear-gradient(0deg, #e8a82c 0%, #ffcd18 100%);*/
  color: #fdc52f;
  color: var(--modulo-4);
  --paraguayoite: url(/static/media/paraguayoite4.6ba233d6.svg);
}
.lpb-4 {
  -webkit-filter: invert(71%) sepia(67%) saturate(516%) hue-rotate(344deg)
    brightness(100%) contrast(103%);
          filter: invert(71%) sepia(67%) saturate(516%) hue-rotate(344deg)
    brightness(100%) contrast(103%);
}

.lp-level5 {
  /*background: linear-gradient(0deg, #00D8CB 0%,  #10CFA1 100%);*/
  color: #00e3d4;
  color: var(--modulo-5);
  --paraguayoite: url(/static/media/paraguayoite5.b5323d0c.svg);
}
.lpb-5 {
  -webkit-filter: invert(81%) sepia(25%) saturate(7165%) hue-rotate(125deg)
    brightness(93%) contrast(101%);
          filter: invert(81%) sepia(25%) saturate(7165%) hue-rotate(125deg)
    brightness(93%) contrast(101%);
}

.lp-level6 {
  /*background: linear-gradient(0deg, #dc4a5c 0%, #ff8c99 100%);*/
  color: #ff78a3;
  color: var(--modulo-6);
  --paraguayoite: url(/static/media/paraguayoite6.a6c54f06.svg);
}
.lpb-6 {
  -webkit-filter: invert(51%) sepia(17%) saturate(3667%) hue-rotate(317deg)
    brightness(113%) contrast(106%);
          filter: invert(51%) sepia(17%) saturate(3667%) hue-rotate(317deg)
    brightness(113%) contrast(106%);
}

.lp-level7 {
  /*background: linear-gradient(0deg, #6788ff 0%, #587cff 100%);*/
  color: #708fff;
  color: var(--modulo-7);
  --paraguayoite: url(/static/media/paraguayoite7.19410009.svg);
}
.lpb-0 {
  -webkit-filter: invert(55%) sepia(49%) saturate(1365%) hue-rotate(201deg)
    brightness(98%) contrast(106%);
          filter: invert(55%) sepia(49%) saturate(1365%) hue-rotate(201deg)
    brightness(98%) contrast(106%);
}

/*Dashboard*/

.Dashboard {
  padding-top: 23px;
  height: calc(100vh - 120px);
  overflow-y: scroll;
}

.dashboard-tabs {
  position: fixed;
  top: 30px;
  left: 0px;
  background-color: white;
  width: calc(100% - 40px);
  max-width: 760px;
  padding: 20px;
  /*Shadows for every platform*/
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

@media only screen and (min-width: 800px) {
  .dashboard-tabs {
    left: calc(50% - 400px);
  }
}

.dashboard-tab {
  width: calc(50% - 20px);
  margin: 0px 8px;
  display: inline-block;
  background-color: white;
  color: #7dce30;
  color: var(--primary-green);
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding: 12px 0px;
  border: solid 2px #7dce30;
  border: solid 2px var(--primary-green);
  border-radius: 20px;
}

.d-tab-active {
  color: white;
  background-color: #7dce30;
  background-color: var(--primary-green);
}

.d-dropdown {
  color: #10b3ed;
  outline: none;
  margin: 0px 30px 36px;
  padding: 4px 0px;
  border: none;
  border-bottom: 2px solid #10b3ed;
  letter-spacing: 1.2px;
  font-size: 17px;
  font-weight: 500;
  background: none;
  width: calc(100% - 60px);
}

.d-data-number {
  margin: 10px 0px 10px 32px;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: #10b3ed;
  width: 52px;
  vertical-align: middle;
}

.d-data-description {
  margin: 10px 32px 10px 0px;
  display: inline-block;
  width: calc(100% - 116px);
  font-size: 14px;
  vertical-align: middle;
}

.d-ranking-title {
  font-size: 13px;
  color: #ffa026;
  color: var(--primary-orange);
  text-transform: uppercase;
  margin: 50px 30px 10px;
  width: calc(100% - 60px);
  letter-spacing: 0.8px;
}

.d-ranking-position {
  margin: 10px 0px 10px 32px;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: #10b3ed;
  width: 45px;
  vertical-align: middle;
}

.d-ranking-description {
  margin: 10px 0px;
  display: inline-block;
  width: calc(100% - 161px);
  font-size: 14px;
  vertical-align: middle;
}

.d-ranking-points {
  margin: 10px 32px 10px 0px;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: #7dce30;
  color: var(--primary-green);
  width: 52px;
  vertical-align: middle;
  text-align: end;
}

.dashboard-link-container {
  margin: 5px 0px 20px;
  text-align: center;
}

.dashboard-link {
  text-decoration: none;
  color: #ff9162;
  border-bottom: 1px solid #ff9162;
  padding: 2px 0px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 500;
}
.container-social-media-share-button {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 40px;
}
.custom-twitter-login {
  /* On surface 2 */

  background: #ffffff;
  border-radius: 20px;
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 48px;

  /* BOTON 2 */

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #1da1f2;
}
.custom-twitter-login img {
  margin-right: 10px;
}
.dashboard-title {
  /* Overline */

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: left;
  padding-left: 32px;
  letter-spacing: 0.15em;
  text-transform: uppercase;

  /* Gradient */

  color: #868686;
}

.dashboard-subtitle {
  color: #58cdf6;
  color: var(--primary-blue);
  padding-left: 30px;

  font-family: Poppins;
  font-size: 37px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
  margin-top: 3px;
}

.d-ranking-title-noMargin {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  /* identical to box height */

  letter-spacing: 0.07em;
  text-transform: uppercase;

  /* On surface 1 */

  color: #3c3c3c;

  margin: 10px 31px;
}
.d-medal-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.d-medal-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text-medal {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 23px;
  /* identical to box height, or 192% */

  text-align: center;
}
.bronze {
  color: #b87333;
}
.silver {
  color: #999999;
}
.gold {
  color: #ffcb11;
}
.d-tab-container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  padding: 10px 0px;
  -webkit-clip-path: inset(0px 0px -15px 0px);
          clip-path: inset(0px 0px -15px 0px);
  box-shadow: 1px 1px 15px rgb(0 0 0 / 25%);
  margin-bottom: 20px;
}
.d-ranking-tab {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #00a3dd;

  /* Container */
  width: 30%;
  height: 43px;
  border-radius: 20px;
  border: 2px solid #00a3dd;
  background-color: white;
  margin-left: 1%;
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.d-tab-active {
  color: white;
  background: #00a3dd;
  border-radius: 20px;
}
.division-line {
  /* Grey */
  width: 92%;
  height: 0px;
  border-top: 2px solid #f2f2f2;
  margin: auto;
}
/* .d-ranking-tabLeft {
  display: inline-block;
  text-align: center;
  margin: 10px 0px 10px 28px;
  padding: 10px 0px;
  color: var(--primary-orange);
  width: calc(50% - 28px);
  opacity: 0.6;
} */

/* .d-ranking-tabRight {
  display: inline-block;
  text-align: center;
  margin: 10px 28px 10px 0px;
  padding: 10px 0px;
  color: var(--primary-orange);
  width: calc(50% - 28px);
  opacity: 0.6;
} */

/* .d-r-tab-active {
  opacity: 1;
  border-bottom: 2px solid var(--primary-orange);
} */

.d-s-graphics {
  margin-top: 38px;
}

.d-graphics-container {
  margin: 0px 0px 30px 20px;
  width: calc(100% - 40px);
  overflow-x: scroll;
  white-space: nowrap;
  padding: 10px 0px;
}

.d-graphics-card {
  display: inline-block;
  margin: 0px 10px;
  border-radius: 20px;
  background-color: white;
  width: 320px;
  /*Shadows for every platform*/
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.25);
}

.d-graphic {
  display: inline-block;
  vertical-align: top;
  padding: 10px 0px;
}

.d-graphic-title {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  text-align: center;
  color: #10b3ed;
  margin: 15px 0px 10px;
}

.d-graphics-card svg {
  font-size: 13px;
}

.d-graphic-description {
  padding: 15px 0px;
  max-height: 160px;
  font-size: 13px;
  width: 90px;
  display: inline-block;
  vertical-align: top;
}

.d-girls {
  color: #ff78a3;
  color: var(--modulo-6);
}

.d-boys {
  color: #708fff;
  color: var(--modulo-7);
}

.d-notGender {
  white-space: pre-wrap;
  color: #ff9e30;
  color: var(--modulo-3);
}

.termsAndConditionsText {
  margin: 0px 1rem;
}

.termsAndConditionsText > p,
.privacityPolicy > p {
  margin: 1rem 0px 3rem;
}

.text-link {
  color: #93f2ee;
  color: var(--profile-header-detail);
  font-weight: 500;
}

.error-modal {
  font-size: 14px;
  padding: 20px;
  text-align: center;
  width: calc(100% - 100px);
  z-index: 102;
  background-color: #ffa026;
  background-color: var(--primary-orange);
  border-radius: 20px;
  /*Positioning*/
  position: absolute;
  bottom: 50px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  /*Shadows for every platform*/
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

/*Modal*/
.modalTransition-appear {
  opacity: 0;
  top: calc(150px + 50%);
}
.modalTransition-appear-active {
  opacity: 1;
  top: 50%;
  transition: top 150ms linear, opacity 150ms linear;
}

.modalTransition-enter {
  opacity: 0;
  top: calc(150px + 50%);
}
.modalTransition-enter-active {
  opacity: 1;
  top: 50%;
  transition: top 150ms linear, opacity 150ms linear;
}

.modalTransition-exit {
  opacity: 1;
}
.modalTransition-exit-active {
  opacity: 0;
  top: calc(150px + 50%);
  transition: top 150ms linear, opacity 150ms linear;
}
/*####*/

/*Mini Modal*/
.miniModalTransition-appear {
  opacity: 0;
  top: 55%;
}
.miniModalTransition-appear-active {
  opacity: 1;
  top: 45%;
  transition: opacity 150ms, top 150ms;
}

.miniModalTransition-enter {
  opacity: 0;
  top: 55%;
}
.miniModalTransition-enter-active {
  opacity: 1;
  top: 45%;
  transition: opacity 150ms, top 150ms;
}

.miniModalTransition-exit {
  opacity: 1;
  top: 45%;
}
.miniModalTransition-exit-active {
  opacity: 0;
  top: 55%;
  transition: opacity 150ms, top 150ms;
}
/*#####*/

/*Exit Modal*/
.exitModalTransition-enter {
  top: calc(50% + 200px) !important;
  opacity: 0;
}
.exitModalTransition-enter-active {
  top: 50% !important;
  opacity: 1;
  transition: opacity 150ms, top 150ms;
}

.exitModalTransition-exit {
  top: 50% !important;
  opacity: 1;
}
.exitModalTransition-exit-active {
  top: calc(50% + 200px) !important;
  opacity: 0;
  transition: opacity 150ms, top 150ms;
}
/*#####*/

/*FunFact*/
/*.funFactTransition-enter {
    top: 100px !important;
    opacity: 0;
}
.funFactTransition-enter-active {
    top: 0
    opacity: 1;
    transition: opacity 150ms, top 150ms;
}*/

.funFactTransition-exit {
  top: 0% !important;
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}
.funFactTransition-exit-active {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  transition: opacity 250ms, -webkit-transform 250ms;
  transition: opacity 250ms, transform 250ms;
  transition: opacity 250ms, transform 250ms, -webkit-transform 250ms;
}
/*#####*/

/*lqModal*/
.lqModalTransition-enter {
  top: 100vh !important;
  opacity: 0;
}
.lqModalTransition-enter-active {
  top: 0px !important;
  opacity: 1;
  transition: opacity 250ms, top 250ms;
}

.lqModalTransition-exit {
  top: 0px !important;
  opacity: 1;
}
.lqModalTransition-exit-active {
  top: 100vh !important;
  opacity: 0;
  transition: opacity 250ms, top 250ms;
}


.color-1 {
        --color: #58CDF6;
        --colorT: #58CDF644;
}
.color-2 {
        --color: #7DCE30;
        --colorT: #7DCE3044;
}
.color-3 {
        --color: #FFA026;
        --colorT: #FFA02644;    
}
.color-4 {
        --color: #FFBA35;
        --colorT: #FFBA3544;
}
.color-5 {
        --color: #00D8CB;
        --colorT: #00D8CB44;
}
.color-6 {
        --color: #FF6A7C;
        --colorT: #FF6A7C44;
}
.color-0 {
        --color: #7593FF;
        --colorT: #7593FF44;
}
.color-default {
        --color: black;
        --colorT: rgba(0,0,0,0.25);
}

.spinner1 {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50px;
    width: 50px;
    -webkit-transform: translate(-50%, calc(-50% - 30px));
            transform: translate(-50%, calc(-50% - 30px));
  }
  
  .spinner1 span {
    position: absolute;
    display: block;
    bottom: 10px;
    width: 9px;
    height: 5px;
    background: var(--colorT);
    -webkit-animation: spinner1 1.5s  infinite ease-in-out;
            animation: spinner1 1.5s  infinite ease-in-out;
  }
  
  .spinner1 span:nth-child(2) {
    left: 11px;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  
  .spinner1 span:nth-child(3) {
    left: 22px;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  
  .spinner1 span:nth-child(4) {
    left: 33px;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }
  
  .spinner1 span:nth-child(5) {
    left: 44px;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }
  
  @keyframes spinner1 {
    0% {
      height: 5px;
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      background: var(--colorT);
    }
    25% {
      height: 30px;
      -webkit-transform: translateY(15px);
              transform: translateY(15px);
      -webkit-transform: translateY(15px);
              transform: translateY(15px);
      background: var(--color);
    }
    50% {
      height: 5px;
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      background: var(--colorT);
    }
    100% {
      height: 5px;
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      background: var(--colorT);
    }
  }
  @-webkit-keyframes spinner1 {
    0% {
      height: 5px;
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      background: var(--colorT);
    }
    25% {
      height: 30px;
      -webkit-transform: translateY(15px);
              transform: translateY(15px);
      background: var(--color);
    }
    50% {
      height: 5px;
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      background: var(--colorT);
    }
    100% {
      height: 5px;
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      background: var(--colorT);
    }
  }
.LandingPage {
  text-align: center;
  max-width: 800px;
  height: 100vh;
  overflow-y: hidden;
}
.exclamationMark-container {
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.logo-container {
  padding-top: 35px;
  /* height: 115px; */
  /* padding: 0px 0px 30px; */
}
.inputs-and-login-container {
  padding-top: 80px;
  background-image: url(/static/media/curveBlue.789b282c.svg);
  background-position-x: center;
  background-size: cover;
}
.laberinfo-description {
  margin: 0px 40px 40px;
  font-size: 14px;
  font-weight: 500;
}
.login-footer {
  background: #10b3ed;
  border-color: #10b3ed;
  height: calc(100vh - 661px);
  background-image: url(/static/media/curveBlue.789b282c.svg);
  background-position-x: center;
  background-size: cover;
}
.landing-button-play {
  color: var(--primary-green) !important;
  border: 2px solid var(--primary-green) !important;
  padding: 11px 62px !important;
  margin-top: 40px !important;
  margin-bottom: 0px !important;
}
.landing-button {
  margin-top: 25px;
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 6px;
  padding: 15px 40px;
  background-color: var(--primary-green);
  border-radius: 20px;
  border: none;
  outline: none !important;
  /*Shadows for every platform*/
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
}
.button-ranking {
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 43px;
  width: 205px;
  cursor: pointer;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  border-radius: 20px;
  border: 2px solid #ffffff;

  color: white;
}
.center-button {
  display: flex;
  flex-direction: column;
  padding-top: 25%;
  align-items: center;
}
@media (max-height: 570px) {
  .center-button {
    padding-top: 15%;
  }
}
.modal-laberinfo-description {
  margin: 40px 20px 0px;
  text-align: left;
  text-align: initial;
  height: 410px;
  overflow-y: auto;
}

.mld-content {
  height: 410px;
}

.lb-disabled {
  opacity: 0.5;
}

.landing-htpButton {
  color: var(--primary-orange);
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 11px 40px;
  background: white;
  border: solid 2px var(--primary-orange);
  border-radius: 100px;
  outline: none !important;
  /*Shadows for every platform*/
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  margin-top: 18px;
  margin-bottom: 25px;
}

.landing-footer {
  background-image: url(/static/media/curveBlue.789b282c.svg);
  background-position-x: center;
  background-size: cover;
  height: calc(100vh - 347px);
}

.footer-button {
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  background: none;
  padding: 11px 40px;
  border: solid 2px white;
  border-radius: 100px;
  outline: none !important;
  margin-top: calc(30px + 10%);
}

@media only screen and (max-height: 620px) {
  .footer-button {
    margin-top: calc(20px + 7%);
  }
}

.reaccion-logo {
  position: absolute;
  bottom: 17px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
}

/*Login*/
.LoginPage {
  text-align: center;
  max-width: 800px;
}

.landing-input {
  color: white;
  outline: none;
  width: 100%;
  padding: 4px 0px;
  border: none;
  border-bottom: 2px solid;
  letter-spacing: 1.2px;
  font-size: 16px;
  background: none;
}
::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.5;
}
:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.5;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.5;
}

.landing-input-blue {
  color: #10b3ed;
  outline: none;
  width: 100%;
  padding: 4px 0px;
  border: none;
  border-bottom: 2px solid;
  letter-spacing: 1.2px;
  font-size: 16px;
  background: none;
}
.landing-input-blue::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #10b3ed;
  opacity: 0.5;
}
.landing-input-blue:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #10b3ed;
  opacity: 0.5;
}
.landing-input-blue::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #10b3ed;
  opacity: 0.5;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #10b3ed;
  opacity: 0.5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #10b3ed;
  opacity: 0.5;
}

.inputs-container {
  margin: 20px 0px 40px;
}

.input-password {
  margin-top: 40px;
}
.input-password-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  color: white;
  cursor: pointer;
}
.register-link-container {
  margin: 20px 0px 25px;
}

.register-link {
  text-decoration: none;
  color: white;
  border-bottom: 1px solid white;
  padding: 2px 0px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;
}

.register-link-separator {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  width: 1px;
  margin: 0px 12px;
  background-color: white;
}

.outline-button {
  color: var(--primary-blue);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  background: none;
  padding: 11px 30px;
  border: solid 2px var(--primary-blue);
  border-radius: 100px;
  outline: none !important;
}

.google-button {
  border: none;
  background: #ffffff;
  border-radius: 20px !important;
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 44px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #bb001b;
  cursor: pointer;
  -webkit-filter: drop-shadow(1px 1px 15px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(1px 1px 15px rgba(0, 0, 0, 0.25));
}
.google-button img {
  margin-right: 20px;
}

.foco-button {
  border: none;
  background: #ffffff;
  border-radius: 20px !important;
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 44px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #04a8fa;
  cursor: pointer;
}

.foco-button img {
  margin-right: 20px;
}

.black-button {
  color: black;
  border-color: black;
}

.facebook-button {
  color: #1778f2;
  border-color: #1778f2;
  width: 260px;
}

.login-reaccion-logo,
.register-reaccion-logo {
  margin: 18px 0px 15px;
  /* height: 85px; */
}

@media only screen and (min-height: 659px) {
  .login-reaccion-logo {
    position: absolute;
    bottom: 17px;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    margin: 0px;
  }
}

@media only screen and (max-height: 555px) {
  .reaccion-logo {
    display: none;
  }
}

.back-with-bg {
  border-radius: 20px;
  background-color: white;
}

.login-error-modal {
  font-size: 14px;
  padding: 20px;
  text-align: center;
  width: calc(100% - 100px);
  z-index: 101;
  background-color: var(--primary-orange);
  border-radius: 20px;
  /*Positioning*/
  position: absolute;
  bottom: 50px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  /*Shadows for every platform*/
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

.login-error-focomodal {
  font-size: 14px;
  padding: 20px;
  text-align: center;
  width: calc(100% - 100px);
  z-index: 101;
  color: #fff;
  background-color: #ff9d00;
  border-radius: 20px;
  /*Positioning*/
  position: absolute;
  bottom: 50px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  /*Shadows for every platform*/
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

.white-button {
  color: #fff;
  border-color: white;
  background-color: #04a8fa;
}

/*Register*/

.RegisterPage {
  text-align: center;
  max-width: 800px;
}

.input-register {
  margin-bottom: 40px;
  font-size: 13px;
  padding: 2px 0px;
}

.input-less-margin {
  margin-bottom: 25px !important;
}

@media only screen and (min-height: 780px) {
  .register-reaccion-logo {
    position: absolute;
    bottom: 17px;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    margin: 0px;
  }
}

.input-age {
  margin-left: 50px;
  width: calc(50% - 70px);
  margin-right: 20px;
}

.input-gender {
  margin-left: 20px;
  width: calc(50% - 70px);
  margin-right: 50px;
}

.input-option {
  color: #10b3ed;
  background-color: white;
}

.my-facebook-button-class {
  /* Container */
  border: none;
  background: #ffffff;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 44px;

  /* Content */
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #1778f2;

  -webkit-filter: drop-shadow(1px 1px 15px rgba(0, 0, 0, 0.25));

          filter: drop-shadow(1px 1px 15px rgba(0, 0, 0, 0.25));
}
.my-facebook-button-class img {
  margin-right: 10px;
}
.social-media-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.social-media-login-container > * {
  margin-bottom: 7px;
}

.tw-button {
}
.twitter-icon {
  background-color: #1baee4;
  background-image: url("https://i.imgur.com/sO5Y2aG.png");
  display: block;
  float: left;
  width: 28px;
  height: 28px;
  margin: -1px;
  border-radius: 3px;
  /* box-shadow: inset 0 1px 3px #333; */
}
.tw-container {
  background: #1da1f2;
  color: white;
  padding: 9px 24px;
  border-radius: 4rem;
  border: white;
  font-size: 15px !important;
  cursor: pointer;
  /* transition(color .3s, background-color, .4s); */
}
@media (max-height: 640px) {
  .top3-institution {
    display: none;
  }
  .line-divisory {
    display: none;
  }
}
.top3-institution {
  color: white;
  max-width: 265px;
  margin: auto;
  margin-top: 10px;
  font-family: "Poppins", sans-serif !important;
}
.top3-institution .title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;

  /* Accent */

  color: #ff9162;
}

.top3-institution ol {
  list-style-type: none;
  padding: 0;
  /* Setup the counter. */
  counter-reset: yourVariableName;
}
.top3-institution li {
  margin-top: 5px;
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 23px;
  /* or 192% */

  color: #000000;
}
.top3-institution li div {
  text-align: left;
  text-align: initial;
}
.top3-institution img {
  margin-right: 12px;
}
.top3-institution li:before {
  /* Advance the number. */
  counter-increment: yourVariableName;
  /* Use the counter number as content. */
  content: counter(yourVariableName) "   ";
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  margin-right: 10px;
  /* or 164% */

  /* Gradient */

  color: #10b3ed;
}
.line-divisory {
  width: 90%;
  margin: auto;
  height: 0;
  background: linear-gradient(180deg, #f2f2f2 0%, #f2f2f2 100%);
  /* Grey */
  border-top: 2px solid #f2f2f2;
}
.brief-description-container {
  width: 80%;
  margin: auto;
  margin-bottom: 21px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  /* or 164% */

  text-align: center;

  /* On surface 1 */

  color: #3c3c3c;
}

.input-login::-webkit-input-placeholder {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  color: #51c0ff;
}

.input-login:-ms-input-placeholder {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  color: #51c0ff;
}

.input-login::placeholder {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  color: #51c0ff;
}

.input-login:focus-visible {
  outline: none;
}

.lq-content {
  text-align: center;
  padding-top: 10px;
  height: calc(100vh - 10px);
  background: #ffffff;
}

.lq-lvl1 {
  --level-color: var(--modulo-1);
  /*--level-bg: url('../../assets/images/questions/questionBackground1.svg');*/
  --level-close: url(/static/media/closeModal.5a19a4ec.svg);
}

.lq-lvl2 {
  --level-color: var(--modulo-2);
  /*--level-bg: url('../../assets/images/questions/questionBackground2.svg');*/
  --level-close: url(/static/media/closeModal2.338bfead.svg);
}

.lq-lvl3 {
  --level-color: var(--modulo-3);
  /*--level-bg: url('../../assets/images/questions/questionBackground3.svg');*/
  --level-close: url(/static/media/closeModal3.7d53443c.svg);
}

.lq-lvl4 {
  --level-color: var(--modulo-4);
  /*--level-bg: url('../../assets/images/questions/questionBackground4.svg');*/
  --level-close: url(/static/media/closeModal4.0fc05ca3.svg);
}

.lq-lvl5 {
  --level-color: var(--modulo-5);
  /*--level-bg: url('../../assets/images/questions/questionBackground5.svg');*/
  --level-close: url(/static/media/closeModal5.740725d2.svg);
}

.lq-lvl6 {
  --level-color: var(--modulo-6);
  /*--level-bg: url('../../assets/images/questions/questionBackground6.svg');*/
  --level-close: url(/static/media/closeModal6.17447211.svg);
}

.lq-lvl7 {
  --level-color: var(--modulo-7);
  /*--level-bg: url('../../assets/images/questions/questionBackground7.svg');*/
  --level-close: url(/static/media/closeModal7.407838bc.svg);
}

.lq-timer {
  position: absolute;
  text-align: start;
  top: 24px;
  left: 15px;
  color: var(--level-color);
  font-size: 24px;
  font-weight: 500;
  display: none;
}

@media only screen and (min-width: 800px) {
  .lq-timer {
    left: calc(50% - 380px);
  }
}

.lq-close {
  position: absolute;
  top: 24px;
  right: 15px;
  height: 29px;
  width: 29px;
  background-image: var(--level-close);
  background-repeat: no-repeat;
  background-size: contain;
}

@media only screen and (min-width: 800px) {
  .lq-close {
    left: calc(50% + 356px);
  }
}

.lq-title {
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 1.8px;
  color: var(--level-color);
  text-transform: uppercase;
  display: block;
  margin: 17px 50px;
  font-family: "Poppins";
  font-style: normal;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #3c3c3c;
}

.lq-question {
  font-size: 18px;
  font-weight: 500;
  height: 165px;
  /*border: 4px solid var(--level-color);
    border-radius: 10px;*/
  display: flex;
  margin: 25px auto 10px;
  padding: 15px;
  width: 80%;
  align-items: flex-start;
  justify-content: center;
  color: var(--level-color);
}

@media screen and (max-width: 800px) {
  .lq-question {
    height: 0;
    /*border: 4px solid var(--level-color);
    border-radius: 10px;*/
  }
}

.lq-question > span {
  text-align: center;
  z-index: 91;
}

/*background*/
.lq-body {
  background-image: var(--level-bg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 287px);
}

.lq-answer-progress {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background: #fdfdfd;
  border-radius: 10px;
  height: 308px;
}

@media only screen and (min-width: 801px) {
  .lq-answer-progress {
    left: calc(50% - 380px);
    right: calc(50% - 380px);
  }
}

/*Real content*/
.lq-answer-content {
  position: absolute;
  bottom: 30px;
  width: 100%;
  max-width: 800px;
}

.lq-answer {
  font-size: 14px;
  height: 64px;
  border: 2px solid var(--level-color);
  background-color: white;
  border-radius: 20px;
  display: flex;
  margin: 14px auto;
  padding: 0px 15px;
  width: 80%;
  max-width: 640px;
  align-items: center;
  justify-content: center;
  /*Shadows for every platform*/
  /*-webkit-box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.25);
    box-shadow: 1px 1px 15px 0px rgba(0,0,0,0.25);*/
}

@media only screen and (min-width: 800px) {
  .lq-answer {
    margin: 14px 63px;
  }
}

.lq-answer:hover {
  background-color: #7dce30;
  color: #fff;
}

.lq-progress {
  position: absolute;
  bottom: 18px;
  margin: auto;
  height: 6px;
  width: calc(100% - 40px);
  max-width: 760px;
  margin: 0 20px;
  border-radius: 3px;
  background-color: rgb(155, 155, 155, 0.5);
  z-index: 99;
}

.lq-bar {
  background-color: #7dce30aa;
  height: 100%;
}

.lq-modal {
  padding: 20px;
  z-index: 105;
  background-color: white;
  border-radius: 20px;
  max-width: 260px;
  color: var(--level-color);
  /*Centering*/
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /*Shadows for every platform*/
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

/*@media only screen and (min-width: 800px) {
    .lq-modal {
        left: 400px;
    }
}*/

.lq-modal-title {
  text-align: start;
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 25px);
  font-size: 18px;
  font-weight: 500;
}

.lq-modal-close {
  display: inline-block;
  vertical-align: middle;
  height: 25px;
  width: 25px;
  background-image: var(--level-close);
  background-repeat: no-repeat;
  background-size: contain;
}

.lq-modal-body {
  text-align: start;
  margin-top: 20px;
  margin-bottom: 25px;
}

.lq-button {
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 8px 25px;
  background: var(--level-color);
  border-radius: 100px;
  border: 1px solid var(--level-color);
  outline: none !important;
  width: 190px;
  margin-bottom: 15px;
}

.lq-screenAnswer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: white;
  max-width: 800px;
  height: 100vh;
  z-index: 100;
}

@media only screen and (min-width: 800px) {
  .lq-screenAnswer {
    left: calc(50% - 400px);
  }
}

.lq-correct {
  --sa-background: url(/static/media/correct.67e4ce44.svg);
  --sa-height: calc(100vh - 270px);
  --sa-color: var(--modulo-2);
}

.lq-incorrect {
  --sa-background: url(/static/media/incorrect.5ad5c886.svg);
  --sa-height: calc(100vh - 270px);
  --sa-color: var(--modulo-3);
}

.lq-sa-background {
  padding-top: 10px;
  height: var(--sa-height);
  width: 100%;
  /*background-image: var(--sa-background);*/
  /*--level-close: url('../../assets/images/interface/closeWhite.svg');*/
  background-size: cover;
  background-position: bottom;
  color: var(--level-color);
}

.lq-sa-background > .lq-title {
  padding-top: 17px;
  margin-top: 0px;
}

.lq-sa-answer {
  font-size: 37px;
  font-weight: 600;
  margin-top: 35px;
  color: var(--sa-color);
}

.secondary {
  color: var(--level-color);
  background-color: white;
}

.lq-sa-image-holder {
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
  border-radius: 10px;
  height: calc(var(--sa-height) - 173px - 5vh);
  background-color: rgba(255, 255, 255, 0.3);
  align-items: center;
}

.lq-sa-image {
  max-height: 100%;
  margin: auto;
  max-width: 90%;
  margin: 20px;
}

.lq-sa-text {
  display: flex;
  align-items: center;
  height: 150px;
  width: calc(100% - 60px);
  max-width: 770px;
  position: absolute;
  bottom: 110px;
  padding: 0px 30px;
  font-weight: 500;
}

.lq-sa-text > span {
  width: 100%;
}

.lq-sa-button {
  position: absolute;
  bottom: 42px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  background: linear-gradient(#ff9162, #ff7e46);
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 6px;
  padding: 16px 50px;
  border-radius: 20px;
  border: none;
  outline: none !important;
  /*Shadows for every platform*/
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.4);
}

.lq-incorrect > .lq-sa-button,
.lq-fun-fact > .lq-sa-button {
  background: #ff9162;
}

.lq-fun-fact {
  --sa-height: calc(100vh - 240px);
}

.lq-sa-image-ff {
  max-height: 90%;
  margin: auto;
  max-width: 90%;
}

.move-pet {
  bottom: 182px !important;
}

.lq-girl {
  position: absolute;
  z-index: 90;
  bottom: 311px;
  left: 50%;
  width: 200px;
  height: auto;
  -webkit-transform: translate(-50%, 0px);
          transform: translate(-50%, 0px);
}

@media only screen and (max-width: 800px) {
  .lq-girl {
    position: absolute;
    z-index: 90;
    bottom: 315px;
    left: 50%;
    width: 165px;
    height: auto;
    -webkit-transform: translate(-50%, 0px);
            transform: translate(-50%, 0px);
  }
}

.lq-pet-0 {
  position: absolute;
  z-index: 90;
  bottom: 264px;
  left: 50%;
  height: 100px;
  -webkit-transform: translate(-50%, 0px);
          transform: translate(-50%, 0px);
}
.lq-pet-1 {
  position: absolute;
  z-index: 0;
  bottom: 250px;
  left: 0px;
  height: 150px;
}
.lq-pet-2 {
  position: absolute;
  z-index: 0;
  bottom: 250px;
  right: 0px;
  height: 150px;
}

.lq-pet-3 {
  position: absolute;
  z-index: 90;
  bottom: 264px;
  left: 50%;
  height: 100px;
  -webkit-transform: translate(-50%, 0px);
          transform: translate(-50%, 0px);
}

@media only screen and (min-width: 800px) {
  .lq-pet-1 {
    left: calc(50% - 400px);
  }

  .lq-pet-2 {
    right: calc(50% - 400px);
  }
}

.lq-finish-container {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}

.lq-finish-title {
  margin-top: 5%;
  font-size: 20px;
  font-weight: 500;
}

.lq-finish-title > h1 {
  margin: 0px;
  color: var(--level-color);
  font-size: 37px;
  font-weight: 600;
}

.lq-finish-stats {
  color: var(--level-color);
  text-align: start;
  font-size: 20px;
  font-weight: 500;
  padding: 25px 30px;
  width: (90% - 60px);
  min-width: 260px;
}

.lqf-button {
  display: block;
  color: white;
  text-transform: uppercase;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 6px;
  padding: 14px 35px;
  background: var(--level-color);
  border-radius: 20px;
  border: 1px solid var(--level-color);
  outline: none !important;
  margin: 50px auto 0px;
  /*Shadows for every platform*/
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.2);
}

.lq-bonus {
  display: block;
  margin: 15px auto 0px;
  background: linear-gradient(#ff9162, #ff7e46);
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 6px;
  padding: 16px 50px;
  border-radius: 20px;
  border: none;
  outline: none !important;
  /*Shadows for every platform*/
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.2);
}

.flexBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.header-share-certificate {
  color: white;
  width: 100%; /* EXTRA 2 */

  background: #00acf1;
  height: 17vh;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.15em;
  text-transform: uppercase;

  color: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.header-share-certificate #name {
  /* Subtitulo */

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.07em;
  text-transform: uppercase;

  /* On surface 2 */

  color: #ffffff;
}
.header-share-certificate #title-container {
  margin-left: 40px;
}
.blue-line-separator {
  border-bottom: 1px solid rgba(147, 242, 238, 0.5);
}
.body-share-certificate #title-container {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  margin: -20px 0;
  color: #3c3c3c;
  padding: 20px;
}
.body-share-certificate #medals {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px;
}
#id .medals {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* ---------------------- */

.certificate-table #row {
  justify-content: space-between;
  padding: 15px 15%;
  display: flex;
  flex-direction: row;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #000000;
}

.certificate-table #title-row {
  /* Overline */

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #c2c2c2;
}
.button-share-certificate {
  max-width: 180px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 43px;
  background: linear-gradient(180deg, #ffa026 0%, #ff9d21 100%);
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  color: #ffffff;

  margin-top: 40px;
}
.button-download-certificate {
  max-width: 180px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 11px 43px;
  //border: 1px solid #58cdf7;
  box-sizing: border-box;
  border-radius: 20px;
  margin-top: 20px;
  cursor: pointer;
}
.button-download-certificate {
  text-decoration: none;
  color: #58cdf7;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.box-green {
  //border: 4px #0ecfa4 solid;
  background-color: #fff;
  margin: 0px 14px;
  padding-bottom: 10%;
  border-bottom: none;
  margin-bottom: -31px;
}

.center-monstruito {
  margin-bottom: -3px;
  display: flex;
  justify-content: center;
  margin-top: 14px;
}
.bottom-height {
  height: calc(100vh - 584px);
}
@media (max-height: 600px) {
  .bottom-height {
    height: calc(100vh - 520px) !important;
  }
}
.medal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.lp-back2 {
  position: fixed;
  top: 23px;
  left: 17px;
}
@media only screen and (min-width: 800px) {
  .lp-back2 {
    left: calc(50% - 377.5px);
  }
}
.blue-line-separator-row {
  border-bottom: 1px solid #eef5ff;
  width: 95%;
  margin: auto;
}

.button {
  margin: 0px 10px;
  width: 155px;
  height: 38px;
  border-radius: 20px;
  padding: 0;
  //padding: 10px 35px 10px 35px;
  color: #7dce30;
  border: 1px solid #7dce30;
  background: #fff;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.1em;
  cursor: pointer;
  margin-top: 22px;
  margin-bottom: 30px;
}
.button2 {
  padding: 0;
}
.button-active {
  color: #fff;
  background: #7dce30;
}

.link {
  display: block;
  padding: 10px;
  box-shadow: none;
  text-decoration: none;
  color: #7dce30;
}

.link-active {
  display: block;
  padding: 10x;
  box-shadow: none;
  text-decoration: none;
  color: #fff;
}

/*.MuiTypography-h6 {
  color: #10b3ed;
}*/
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #fff;
}
hr {
  border: 1px solid #f2f2f2;
  width: 100%;
}

.container-table {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-x: auto;
}
/*table*/
.MuiPaper-root {
  width: 100%;
  margin-bottom: 24px;
}

.MuiTable-root {
  background-color: white;
}

.MuiMenu-paper {
  width: 2%;
  height: 150px;
}
.MTableToolbar-root-5 {
  display: none !important;
}

.MuiTableCell-alignRight {
  text-align: left !important;
}

.title {
  width: 194px;
  height: 40px;
  margin-top: 30px;
  color: #10b3ed;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 37px;
  line-height: 40px;
}

.sub-title {
  margin-right: 75px;
  color: #00a3dd;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 30px;
}

.select {
  width: 243.05px;
  height: 50px;
  margin-right: 50px;
  padding: 12px 18px;
  border-radius: 5px;
  border: none;
  background: #f2f2f2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  //appearance: none;
}

.mandatory {
  width: 90px;
  height: 23px;
  color: #ff9162;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
}

.select2 {
  width: 243.05px;
  height: 47px;
  //margin-left: 123px;
  margin-right: 21.95px;
  padding: 12px 18px;
  border-radius: 5px;
  border: none;
  //color: #10b3ed;
  background: #f2f2f2;
  //appearance: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
}

.select3 {
  width: 311.05px;
  margin-right: 0;
}

.downloadLink {
  position: relative;
  color: #10b3ed;
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-right: 35px;
}

.downloadLinkIcon {
  position: absolute;
  margin-left: 19px;
}

.first-column {
  width: 444px;
  height: 78px;
  display: flex;
  align-items: center;
  padding: 2px 0px;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.correctAnswer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 18px;
  width: 147px;
  height: 75px;
  background-color: #7dce30;
  border-radius: 10px;
}
.wrongAnswer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 18px;
  width: 147px;
  height: 75px;
  background-color: #ff9162;
  border-radius: 10px;
  //color: #fff;
}

/*.MuiTypography-h6 {
  color: #10b3ed;
}*/
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #fff;
}
hr {
  width: 100%;
  border: 1px solid #f2f2f2;
}

.container-table {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-x: auto;
}
/*table*/
.MuiPaper-root {
  width: 100%;
  margin-bottom: 24px;
}

.MuiTable-root {
  background-color: white;
}

.MuiMenu-paper {
  width: 2%;
  height: 150px;
}
.MTableToolbar-root-5 {
  display: none !important;
}

/*.MuiTableCell-alignRight {
  text-align: left !important;
}*/

.title-time {
  width: 500px;
  height: 40px;
  margin-top: 30px;
  color: #10b3ed;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 37px;
  line-height: 40px;
}

.sub-title-time {
  color: #00a3dd;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

.selecttime {
  width: 169.05px;
  height: 50px;
  margin-right: 10px;
  padding: 12px 18px;
  border-radius: 5px;
  border: none;
  background: #f2f2f2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  //appearance: none;
}

.selectcomu {
  width: 208.05px;
  height: 47px;
  margin-right: 10px;
  padding: 12px 18px;
  border-radius: 5px;
  border: none;
  background: #f2f2f2;
  //appearance: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
}

.downloadLink {
  position: relative;
  color: #10b3ed;
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-right: 35px;
}

.downloadLinkIcon {
  position: absolute;
  margin-left: 19px;
}

.first-column {
  width: 444px;
  height: 78px;
  display: flex;
  align-items: center;
  padding: 2px 0px;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #fff;
}
hr {
  width: 100%;
  border: 1px solid #f2f2f2;
}

.title-time {
  width: 500px;
  height: 40px;
  margin-top: 30px;
  color: #10b3ed;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 37px;
  line-height: 40px;
}

.sub-title-time {
  color: #00a3dd;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

.selectuser {
  box-sizing: border-box;
  margin-right: 10px;
  width: 200px;
  height: 50px;
  padding: 12px 18px;
  border-radius: 5px;
  border: none;
  background: #f2f2f2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  margin-top: 10px;
  //appearance: none;
}

.downloadLink {
  position: relative;
  color: #10b3ed;
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-right: 35px;
}

.downloadLinkIcon {
  position: absolute;
  margin-left: 19px;
}

.chart-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #3c3c3c;
  margin-bottom: 18px;
}

.select-module {
  width: 156.05px;
  height: 43px;
  left: 667px;
  top: 917px;
  background: #f2f2f2;
  border-radius: 5px;
}

.selectuser-date {
  box-sizing: border-box;
  margin-right: 10px;
  margin-left: 5px;
  width: 200px;
  padding: 13px 18px;
  border-radius: 5px;
  border: none;
  background: #f2f2f2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  margin-top: 10px;
}


/*Modal*/
.modalTransition-appear {
  opacity: 0;
  top: calc(150px + 50%);
}
.modalTransition-appear-active {
  opacity: 1;
  top: 50%;
  transition: top 150ms linear, opacity 150ms linear;
}

.modalTransition-enter {
  opacity: 0;
  top: calc(150px + 50%);
}
.modalTransition-enter-active {
  opacity: 1;
  top: 50%;
  transition: top 150ms linear, opacity 150ms linear;
}

.modalTransition-exit {
  opacity: 1;
}
.modalTransition-exit-active {
  opacity: 0;
  top: calc(150px + 50%);
  transition: top 150ms linear, opacity 150ms linear;
}
/*####*/

/*Mini Modal*/
.miniModalTransition-appear {
  opacity: 0;
  top: 55%;
}
.miniModalTransition-appear-active {
  opacity: 1;
  top: 45%;
  transition: opacity 150ms, top 150ms;
}

.miniModalTransition-enter {
  opacity: 0;
  top: 55%;
}
.miniModalTransition-enter-active {
  opacity: 1;
  top: 45%;
  transition: opacity 150ms, top 150ms;
}

.miniModalTransition-exit {
  opacity: 1;
  top: 45%;
}
.miniModalTransition-exit-active {
  opacity: 0;
  top: 55%;
  transition: opacity 150ms, top 150ms;
}
/*#####*/

/*Exit Modal*/
.exitModalTransition-enter {
  top: calc(50% + 200px) !important;
  opacity: 0;
}
.exitModalTransition-enter-active {
  top: 50% !important;
  opacity: 1;
  transition: opacity 150ms, top 150ms;
}

.exitModalTransition-exit {
  top: 50% !important;
  opacity: 1;
}
.exitModalTransition-exit-active {
  top: calc(50% + 200px) !important;
  opacity: 0;
  transition: opacity 150ms, top 150ms;
}
/*#####*/

/*FunFact*/
/*.funFactTransition-enter {
    top: 100px !important;
    opacity: 0;
}
.funFactTransition-enter-active {
    top: 0
    opacity: 1;
    transition: opacity 150ms, top 150ms;
}*/

.funFactTransition-exit {
  top: 0% !important;
  transform: scale(1);
  opacity: 1;
}
.funFactTransition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 250ms, transform 250ms;
}
/*#####*/

/*lqModal*/
.lqModalTransition-enter {
  top: 100vh !important;
  opacity: 0;
}
.lqModalTransition-enter-active {
  top: 0px !important;
  opacity: 1;
  transition: opacity 250ms, top 250ms;
}

.lqModalTransition-exit {
  top: 0px !important;
  opacity: 1;
}
.lqModalTransition-exit-active {
  top: 100vh !important;
  opacity: 0;
  transition: opacity 250ms, top 250ms;
}


.color-1 {
        --color: #58CDF6;
        --colorT: #58CDF644;
}
.color-2 {
        --color: #7DCE30;
        --colorT: #7DCE3044;
}
.color-3 {
        --color: #FFA026;
        --colorT: #FFA02644;    
}
.color-4 {
        --color: #FFBA35;
        --colorT: #FFBA3544;
}
.color-5 {
        --color: #00D8CB;
        --colorT: #00D8CB44;
}
.color-6 {
        --color: #FF6A7C;
        --colorT: #FF6A7C44;
}
.color-0 {
        --color: #7593FF;
        --colorT: #7593FF44;
}
.color-default {
        --color: black;
        --colorT: rgba(0,0,0,0.25);
}

.spinner1 {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50px;
    width: 50px;
    transform: translate(-50%, calc(-50% - 30px));
  }
  
  .spinner1 span {
    position: absolute;
    display: block;
    bottom: 10px;
    width: 9px;
    height: 5px;
    background: var(--colorT);
    -webkit-animation: spinner1 1.5s  infinite ease-in-out;
            animation: spinner1 1.5s  infinite ease-in-out;
  }
  
  .spinner1 span:nth-child(2) {
    left: 11px;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  
  .spinner1 span:nth-child(3) {
    left: 22px;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  
  .spinner1 span:nth-child(4) {
    left: 33px;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }
  
  .spinner1 span:nth-child(5) {
    left: 44px;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }
  
  @keyframes spinner1 {
    0% {
      height: 5px;
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      background: var(--colorT);
    }
    25% {
      height: 30px;
      -webkit-transform: translateY(15px);
              transform: translateY(15px);
      -webkit-transform: translateY(15px);
              transform: translateY(15px);
      background: var(--color);
    }
    50% {
      height: 5px;
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      background: var(--colorT);
    }
    100% {
      height: 5px;
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      background: var(--colorT);
    }
  }
  @-webkit-keyframes spinner1 {
    0% {
      height: 5px;
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      background: var(--colorT);
    }
    25% {
      height: 30px;
      -webkit-transform: translateY(15px);
              transform: translateY(15px);
      background: var(--color);
    }
    50% {
      height: 5px;
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      background: var(--colorT);
    }
    100% {
      height: 5px;
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      background: var(--colorT);
    }
  }
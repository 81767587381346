.button {
  margin: 0px 10px;
  width: 155px;
  height: 38px;
  border-radius: 20px;
  padding: 0;
  //padding: 10px 35px 10px 35px;
  color: #7dce30;
  border: 1px solid #7dce30;
  background: #fff;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.1em;
  cursor: pointer;
  margin-top: 22px;
  margin-bottom: 30px;
}
.button2 {
  padding: 0;
}
.button-active {
  color: #fff;
  background: #7dce30;
}

.link {
  display: block;
  padding: 10px;
  box-shadow: none;
  text-decoration: none;
  color: #7dce30;
}

.link-active {
  display: block;
  padding: 10x;
  box-shadow: none;
  text-decoration: none;
  color: #fff;
}
